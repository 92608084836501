import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import TextField from '../../components/forms/TextField'
import PasswordField from '../../components/forms/PasswordField'
import EmailField from '../../components/forms/EmailField'
import SelectField from '../../components/forms/SelectField'

import { addUser } from '../../redux/users/userSlice'

import { getLocations } from '../../redux/locations/LocationSlice'
import { clearError } from '../../redux/errors/ErrorSlice'
import { useSelector, useDispatch } from 'react-redux'
import ErrorMessage from '../../components/ErrorMessage'

export default function UsersAdd() {

    const [userForm, setUserForm] = useState({})
    const [companies, setCompanies] = useState([])
    const [locations, setLocations] = useState([])
    const [submitted, setSubmitted] = useState(false)

    const userRedux = useSelector(state => state.User)
    const authRedux = useSelector(state => state.Auth)
    const companyRedux = useSelector(state => state.Company)
    const locationRedux = useSelector(state => state.Location)
    const errorRedux = useSelector(state => state.Error)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()

    useEffect(() => {
        const temp = companyRedux.companies.map( el => Object({ label: el.name, value: el.id }))
        setCompanies(temp)
    }, [companyRedux.companies])

    useEffect(() => {
        if (userRedux.isButtonLoading) {
            dispatch( clearError() )
        }
        if (submitted && !userRedux.isButtonLoading && !errorRedux.errorMessage) {
            navigate(-1)
        }
    }, [userRedux.isButtonLoading])

    useEffect(() => {
        if (userForm?.company?.fieldValue) {
            dispatch( getLocations({ companyId: userForm?.company?.fieldValue }) )
        }
    }, [userForm])


    const submitUserForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        let hasError = false

        for (const key in userForm) {
            if (userForm[key].isRequired && !userForm[key].fieldValue || userForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const name = userForm.name.fieldValue
            const email = userForm.email.fieldValue
            const password = userForm.password.fieldValue

            const authRole = authRedux.user.claims.role
            // return


            const role = userForm?.role?.fieldValue
            const companyId = authRole == 0 ? userForm.company.fieldValue : params.companyId
            const locationId = userForm?.location?.fieldValue 

            dispatch(addUser({
                name: name,
                email: email,
                password: password,
                companyId: companyId,
                ... locationId && {
                    locationId: locationId
                },
                ... authRole == 1 ? {
                    role: 2
                } : {
                    role: userForm.role.fieldValue
                }
            }))

        }

    }

    return <form onSubmit={submitUserForm}>
        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>

        <TextField
            fieldName="name"
            label="User Name"
            isSubmitted={submitted}
            isRequired
            onChange={e => setUserForm(userForm => ({ ...userForm, name: e }))}
        />

        <EmailField
            fieldName="email"
            label="User Email"
            isSubmitted={submitted}
            isRequired
            onChange={e => setUserForm(userForm => ({ ...userForm, email: e }))}
        />


        <PasswordField
            label="User Password"
            fieldName="password"
            isRequired
            isSubmitted={submitted}
            onChange={e => setUserForm(userForm => ({ ...userForm, password: e }))}
        />

        {/* if user.role === 0 // if user is a super admin */}
        {
           authRedux.user.claims.role === 0 ? <SelectField
                legend="Choose a role"
                label="User Role"
                fieldName="role"
                isRequired
                isSubmitted={submitted}
                onChange={e => setUserForm(userForm => ({ ...userForm, role: e }))}
                options={
                    [
                        { label: "Super Admin", value: 0},
                        { label: "Manager", value: 1 },
                        { label: "Staff", value: 2 }
                    ]}
            /> : null
        }
        {
            userForm?.role?.fieldValue == 0 || userForm?.role?.fieldValue == 1 ||  userForm?.role?.fieldValue == 2 ? <div className='mt-8'> <SelectField
                label="Company"
                legend="Choose Company"
                fieldName="company"
                isRequired
                isSubmitted={submitted}
                onChange={e => setUserForm(userForm => ({...userForm, company: e}))}
                options={companies} /> </div> : null
        }
        {
             userForm?.role?.fieldValue == 2 || authRedux.user.claims.role == 1 ? <div className='mt-8'>
                <SelectField 
                label="Location"
                isRequired
                legend="Choose Location"
                fieldName="location"
                isSubmitted={submitted}
                onChange={e => setUserForm(userForm => ({...userForm, location: e}))}
                options={locationRedux.locations.map( el => Object({ label: el.name, value: el.id })) }
                />
             </div> : null
        }


        <div className="mt-8">
        <button
            type="submit"
            disabled={userRedux.isButtonLoading}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
            {
                userRedux.isButtonLoading ? <span className='ml-2'>Adding User...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className='ml-2'>Add User</span></>
            }

        </button>
        </div>
        
        <div className='mt-3'>
        { 
            errorRedux.errorMessage ? <ErrorMessage message={ errorRedux.errorMessage} /> : null
        }
        </div>
        
    </form>
}