/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPass } from '../redux/auth/authSlice'

import EmailField from '../components/forms/EmailField'
import PasswordField from '../components/forms/PasswordField'
import ErrorMessage from '../components/ErrorMessage'

export default function Login() {
  const [submitted, setSubmitted] = useState(false)
  const [loginForm, setLoginForm] = useState()
  const [success, setSuccess] = useState(false)

  const dispatch = useDispatch()

  const errorRedux = useSelector(state => state.Error)
  const authRedux = useSelector(state => state.Auth)

  useEffect(() => {
    if (submitted && !authRedux.isButtonLoading) {
      setSuccess(true)
    }
  }, [authRedux.isButtonLoading])

  const submitForm = (ev) => {
    ev.preventDefault()
    setSubmitted(true)

    let hasError = false

    for (const key in loginForm) {
      if (loginForm[key].isRequired && !loginForm[key].fieldValue || loginForm[key].error) {
        hasError = true
        break
      } else {
        hasError = false
      }
    }

    if (!hasError) {
      const email = loginForm.email.fieldValue
      dispatch(forgotPass({ email: email }))
    }



  }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-40 w-auto"
              src="images/logo-outline.png"
              alt="Your Company"
            />
            <h2 className="mt-14 text-center text-3xl font-bold tracking-tight text-gray-900">
              Forgot Password
            </h2>
            <small> We will send you instructions in your email</small>
          </div>
          {
            success ? <div class="bg-green-100 border-l-4 border-green-500 text-black p-4" role="alert">
            <p class="font-bold">Reset Password Link Sent</p>
            <p>We sent you instructions on reseting your password in your email.</p>
          </div> : null
          }
      

          <form className="mt-8 space-y-6" onSubmit={submitForm}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <EmailField
                  label="Email Address"
                  fieldName="email"
                  isSubmitted={submitted}
                  isRequired
                  onChange={e => setLoginForm(prev => ({ ...prev, email: e }))} />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                {
                  authRedux.isButtonLoading ? 'Sending link...' : 'Send Link'
                }
              </button>
            </div>

            {errorRedux.errorMessage ? <ErrorMessage message={errorRedux.errorMessage} /> : null}
            
          </form>
        </div>
      </div>
    </>
  )
}
