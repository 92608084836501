import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import NumberField from '../../components/forms/NumberField'
import SelectField from '../../components/forms/SelectField'
import { useDispatch, useSelector } from 'react-redux'
import { editOrder, deleteOrder } from '../../redux/orders/OrderSlice'
import { getCompanyProducts } from '../../redux/companyProducts/CompanyProductsSlice'
import TextField from '../../components/forms/TextField'
import OrderModal from './OrderModal'

export default function OrdersEdit() {
  const [order, setOrder] = useState({})

  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useDispatch()

  const orderRedux = useSelector(state => state.Order)
  const locationRedux = useSelector(state => state.Location)
  const authRedux = useSelector(state => state.Auth)

  const companyProductRedux = useSelector(state => state.CompanyProduct)

  const [submitted, setSubmitted] = useState(false)
  const [orderForm, setOrderForm] = useState({})
  const [products, setProducts] = useState(companyProductRedux.products)
  const [selectedSingleOrder, setSelectedSingleOrder] = useState(null)

  useEffect(() => {
    const orderInfo = orderRedux.orders.filter(el => el.id === params.orderId)
    const orderedProducts = orderInfo[0].products


    dispatch(getCompanyProducts({ companyId: orderInfo[0].buyerDetails.company }))
    setProducts(companyProductRedux.products)

    const sanitizedProducts = products.map(el => {
      const orderedProd = orderedProducts.filter(product => product.id === el.id).pop()

      return { ...el, quantity: orderedProd?.quantity || 0, totalPrice: orderedProd?.totalPrice || 0 }

    })

    setProducts(sanitizedProducts)
    setOrder(orderInfo[0])


  }, [orderRedux.orders])

  useEffect(() => {
    const orderInfo = orderRedux.orders.filter(el => el.id === params.orderId).pop()
    dispatch(getCompanyProducts({ companyId: orderInfo?.buyerDetails?.company }))

  }, [])

  const closeModal = () => {
    setSelectedSingleOrder(null)
  }

  useEffect(() => {
    // if (order?.buyerDetails?.company) {
    const orderInfo = orderRedux.orders.filter(el => el.id === params.orderId).pop()

    const sanitizedProducts = companyProductRedux.products.map(el => {
      const orderedProd = orderInfo?.products?.filter(product => product.id === el.id).pop()
      return { ...el, quantity: orderedProd?.quantity || 0, totalPrice: orderedProd?.totalPrice || 0 }
    })

    setProducts(sanitizedProducts)

    // }
  }, [companyProductRedux.products])

  useEffect(() => {

  }, [products])

  useEffect(() => {
    if (submitted && !orderRedux.isButtonLoading) {
      navigate(-1)
    }
  }, [orderRedux.isButtonLoading])

  const openProductModal = (product) => {
    if (selectedSingleOrder) {
      setSelectedSingleOrder(null)
    } else {
      setSelectedSingleOrder(product)
    }
  }

  const showDeletePrompt = () => {
    const desicion = window.confirm('Are you sure to delete this order')
    if (desicion) {
        dispatch(deleteOrder({
            orderId: params.orderId
        }))
        navigate(-1)

    }
    console.info(desicion)
}

  const renderImage = (pictures) => {
    const picture = pictures?.filter(picture => !picture || Object.keys(picture).length === 0 ? null : picture).shift()
    return picture.url
  }

  const quantityOnChange = (ev, product) => {
    if (ev.error.trim().length == 0) {
      const quantity = ev.fieldValue
      const totalPrice = quantity * product.price

      setProducts(prevState => prevState.map(prod => prod.id === product.id ? { ...product, quantity: quantity, totalPrice: totalPrice } : prod))
    }
  }


  const submitOrderForm = (ev) => {
    ev.preventDefault()
    setSubmitted(true)

    const orderedProduct = products.filter(prod => prod.quantity !== 0)
    const orderedProductTotalPrice = orderedProduct.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0)
    const grandTotal = orderedProductTotalPrice + Number(orderForm.delivery.fieldValue || 0)
    const locationId = authRedux.user.claims.role === 1 ? orderForm.location.fieldValue : params.locationId
    const buyerDetails = { user: authRedux.user.uid, company: params.companyId, ...locationId && { location: locationId } }
    const delivery = orderForm.delivery.fieldValue || 0

    // return
    dispatch(editOrder({
      order: {
        buyerDetails: authRedux.user.claims.role === 0 ? order.buyerDetails : buyerDetails,
        products: orderedProduct,
        subtotal: orderedProductTotalPrice,
        status: authRedux.user.claims.role === 0 ? 'completed' : 'submitted',
        id: order.id,
        orderNumber: order.orderNumber,
        created_date: order.created_date,
        delivery: delivery,
        grandTotal: grandTotal
      }
    }))
  }

  return <div>
    <form onSubmit={submitOrderForm}>
      <div className='flex mb-20'>
        <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <span className='ml-2'>Back</span>
        </button>

        {
                        authRedux.user.claims.role == 0 ? <button type='button' onClick={showDeletePrompt} className='inline-flex ml-2 items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span className='ml-2'> Delete </span>
                        </button> : null
                    }
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Edit Order</h1>
          </div>

        </div>

        <div className='w-80 flex justify-start mt-5'>
          <p className='text-lg'> Order #: <span className='text-indigo-600'> {order.orderNumber} </span></p>
        </div>
        <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Product Info
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Unit Price
                </th>

                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {products?.map((product) => (
                <tr key={product.id} className="border-b border-gray-200">
                  <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">

                    <div className="font-medium text-gray-900 flex">
                      {/* <div className='mr-2'>
                        <img className="h-12 w-12 rounded-full" src={renderImage(product?.pictures)} alt="" />
                      </div> */}
                      <p className='text-indigo-600 cursor-pointer' onClick={() => openProductModal(product)}> {product.title} </p>

                    </div>
                    <div className="flex py-5">
                      {
                        product.tags && product.tags?.map((tag, index) => <div
                          style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})` }}
                          className="text-white mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                        >
                          {tag.name}
                        </div>)
                      }
                    </div>
                    <div className="mt-0.5 text-gray-500 sm:hidden">
                      {product.price}
                    </div>
                  </td>
                  <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                    <span className='flex items-center justify-end'>
                      <NumberField
                        name="quantity"
                        defaultValue={product.quantity}
                        customWidth={80}
                        onChange={e => quantityOnChange(e, product)}
                      />
                    </span>
                  </td>
                  <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">${product.price}</td>

                  <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${product?.totalPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                >
                  Subtotal
                </th>
                <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Subtotal
                </th>
                <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${products.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                >
                  Delivery Fee
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Delivery Fee
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                  {
                    authRedux.user.claims.role === 0 ?
                      <span className='flex items-center justify-end'>
                        <NumberField
                          customWidth={60}
                          defaultValue={order.delivery}
                          onChange={e => setOrderForm({ ...orderForm, delivery: e })} />
                      </span>
                      :
                      <span> {order.delivery ? true : 'TBD'} </span>
                  }
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                >
                  Total
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                  ${Number(products.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0) + Number(orderForm.delivery?.fieldValue || 0)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

            <button
              type="submit"
              disabled={orderRedux.isButtonLoading}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {
                orderRedux.isButtonLoading ? <span> Placing Order...</span> : <span> Place Order</span>
              }
            </button>
          </div>
          <OrderModal
            isOpen={selectedSingleOrder ? true : false}
            productInfo={selectedSingleOrder}
            closeModal={closeModal}
          />
        </div>
      </div>
    </form>
  </div>
}