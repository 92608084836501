import { all, put, takeEvery } from 'redux-saga/effects'
import { getIdTokenResult } from 'firebase/auth'
import { logoutUser, loginUser, loginUserSaga, logoutUserSaga, hasError, setUser, setUserSaga, forgotPass, forgotPassSaga } from './authSlice'
import { loginUser as signInUser, logoutUser as signOutUser } from '../../firebase'
import { setError } from '../errors/ErrorSlice'
import axios from 'axios'

function* processLoginUser ({ payload }) {
    try {
        const user = yield signInUser(payload.email, payload.password)
        const idToken = yield getIdTokenResult(user)

        yield put(loginUserSaga({ user: {...user, ...idToken} }))
    } catch (er){
        yield put(hasError())
        yield put(setError({ error: er.code }))
    }
}

function* processLogoutUser ({ payload }) {
    try {
        yield signOutUser()

        yield put(logoutUserSaga())
    } catch (er){
        console.info('singingin out err', er)
    }
}

function* processForgotPass ({ payload }) {
    try {
        const snapshot = yield axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/forgotPassword`, payload)

        yield put( forgotPassSaga({ link: snapshot.data.link }))
    } catch (er) {
        console.info('forgot password err', er)
    }
}

function* processSetUser ({ payload }) {
    try {
        yield put(setUserSaga({ user: payload.user }))
    } catch (er){}
}

export function* watchProcesses() {
    yield takeEvery(loginUser().type, processLoginUser)
    yield takeEvery(logoutUser().type, processLogoutUser)
    yield takeEvery(setUser().type, processSetUser)
    yield takeEvery(forgotPass().type, processForgotPass)
}

function* authSaga() {
    yield all([ watchProcesses() ])
}

export default authSaga