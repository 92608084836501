import moment from 'moment'

export default function UsersListManager({ users, locations, onRowClick }) {
    const roles = ['Super Admin', 'Manager', 'Staff']

    const getLocation = loc => {
        const locationObject = locations.filter( location => location.id === loc).pop()
    
        return locationObject?.name
    }

    const hasUser = () => {
     const userCount = users.filter( user => user.role === 2).length
     return userCount
    }

    const gotoSingleUser = (user) => {
        onRowClick({
            user: user
        })
      }

    return hasUser() === 0 ? <p> No Users </p> : <table className="min-w-full divide-y divide-gray-300">
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
          Name
        </th>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
          Email
        </th>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
          Role
        </th>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
          Location
        </th>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
          Date Created
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
          <span className="sr-only">Edit</span>
        </th>
      </tr>
    </thead>

    <tbody className="divide-y divide-gray-200 bg-white">
      {users.map((user) => (
            user.role == 2 ? <tr key={user.id} className="cursor-pointer" onClick={() => gotoSingleUser(user)}>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 text-indigo-600">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
                <span className="ml-2"> {user.name} </span>
              </div>
  
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
              {user.email}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
              {roles[user.role]}
            </td>
            <td>
              <span className="text-indigo-600"> {getLocation(user.location)} </span>
            </td>
            <td>
              <span className="text-indigo-600"> 
                { moment.unix(user.created_date.seconds ).format( 'MMMM DD, YYYY') } </span>
            </td>
  
          </tr> : null
      ))}
    </tbody>
  </table>
}