import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isButtonLoading: false,
    isSingleLoading: false,
    companies: []
}

const companySlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        getCompanies (state) {
            state.isListLoading = true
        },
        getCompaniesSaga (state, action) {
            state.companies = action.payload.companies
            state.isListLoading = false
        },
        addCompany (state) {
            state.isButtonLoading = true
        },
        addCompanySaga (state, action) {
            state.companies.unshift(action.payload.company)
            state.isButtonLoading = false
        },
        getSingleCompany (state) {
            state.isSingleLoading = true
        },
        getSingleCompanySaga (state, action) {
            state.companies = [action.payload.company, ...state.companies]
            state.isSingleLoading = false
        },
        editCompany (state) {
            state.isButtonLoading = true
        },
        editCompanySaga (state, action) {
            state.companies = state.companies.map(company => company.id !== action.payload.company.id ? company : action.payload.company )
            state.isButtonLoading = false
        },
        deleteCompany (state) {
            state.isButtonLoading = true
        },
        deleteCompanySaga (state, action) {
            state.companies = state.companies.filter(company => company.id !== action.payload.company.id ? company : null )
            state.isButtonLoading = false
        }
    }
})

export const {
    getCompanies,
    getCompaniesSaga,
    addCompany,
    addCompanySaga,
    getSingleCompany,
    getSingleCompanySaga,
    editCompany,
    editCompanySaga,
    deleteCompany,
    deleteCompanySaga
} = companySlice.actions

export default companySlice.reducer