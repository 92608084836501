import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import Dashboard from "../pages/Dashboard";
import Orders from "../pages/Orders/Orders";
import OrderSingle from '../pages/Orders/OrderSingle'
import OrderStaff from "../pages/Orders/components/OrdersStaff";

import OrdersAdd from "../pages/Orders/OrdersAdd";
import OrdersEdit from '../pages/Orders/OrdersEdit'

import { getOrders, getLatestOrderNumber } from "../redux/orders/OrderSlice";
import { getLocations } from '../redux/locations/LocationSlice'
import { getCompanyProducts } from '../redux/companyProducts/CompanyProductsSlice'
import { getUsers } from '../redux/users/userSlice'
import { getSingleCompany } from "../redux/companies/CompanySlice";

const Staff = ({ user, companyId, locationId}) => {

    const dispatch = useDispatch()
    const userRedux = useSelector( state => state.User )

    useEffect(() => {
        dispatch( getOrders({ companyId: companyId, locationId: locationId, role: user.claims.role }) )
        dispatch( getLocations({ companyId: companyId }))
        dispatch(getLatestOrderNumber({ }))
        dispatch( getCompanyProducts({ companyId: companyId }))
        dispatch(getUsers({ companyId: companyId, role: user.claims.role }))
        dispatch(getSingleCompany({ companyId: companyId }))
    }, [])

    return <Routes>
        <Route path="/" element={<Navigate to={`/companies/${companyId}/locations/${locationId}/orders`} />} />
        <Route path="/companies/:companyId/locations/:locationId/orders" element={<Orders />} />
        <Route path="/companies/:companyId/locations/:locationId/orders/:orderId" element={<OrderSingle />} />
        <Route path="/companies/:companyId/locations/:locationId/orders/:orderId/edit" element={<OrdersEdit />} />
        <Route path="/companies/:companyId/locations/:locationId/orders/add" element={<OrdersAdd />} />

        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
}

export default Staff