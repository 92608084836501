import {
    HomeIcon,
    DocumentChartBarIcon,
    UserIcon,
    BuildingOffice2Icon,
    ArrowLeftOnRectangleIcon,
    ShoppingBagIcon,
  } from '@heroicons/react/24/outline'
import Dashboard from '../pages/Dashboard'
import Orders from '../pages/Orders/Orders'
import Companies from '../pages/Companies'
import Products from '../pages/Products'
import CompanyProducts from '../pages/CompanyProducts'
import UsersList from '../pages/Users/UsersList'

export default function getRoutes(role, company = "", location = ""){
    return {
        ...role === 0 && {
            0: [
                { path: "/orders", name: "Orders", icon: DocumentChartBarIcon, current: false, element: <Orders />},
                { path: "/companies", name: "Companies", icon: BuildingOffice2Icon, current: false, element: <Companies />},
                { path: "/products", name: "Products", icon: ShoppingBagIcon, current: false, element: <Products />},
                { isFunction: true, name: "Logout", icon: ArrowLeftOnRectangleIcon, current: false  }
            ],
        },
        ...role === 1 && {
            1: [
                { path: `/companies/${company}/orders`, name: "Orders", icon: DocumentChartBarIcon, current: false, element: <Orders />},
                { path: `/companies/${company}/products`, name: "Products", icon: ShoppingBagIcon, current: false, element: <CompanyProducts />},
                { path: `/companies/${company}/users`, name: "Users", icon: UserIcon, current: false, element: <UsersList />},
                { isFunction: true, name: "Logout", icon: ArrowLeftOnRectangleIcon, current: false, path: '/logout'  }
            ]
        },
        ...role === 2 && {
            2: [
                { path: `/companies/${company}/locations/${location}/orders`, name: "Orders", icon: DocumentChartBarIcon, current: false, element: <Orders />},
                { isFunction: true, name: "Logout", icon: ArrowLeftOnRectangleIcon, current: false, path: '/logout'  }
            ]
        }
    }
}