import { useEffect, useState } from "react"
import TextField from "../components/forms/TextField"
import TextAreaField from "../components/forms/TextAreaField"
import { useNavigate, useParams } from "react-router-dom"
import QuestionDialog from '../components/QuestionDialog'

import { useDispatch, useSelector } from "react-redux"
import { editLocation, getLocations, getSingleLocation, deleteLocation } from "../redux/locations/LocationSlice"

export default function LocationSingle() {
    const [submitted, setSubmitted] = useState(false)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [locationForm, setLocationForm] = useState(null)
    const [location, setLocation] = useState(null)
    const [showDelete, setShowDelete] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()


    const locationRedux = useSelector(state => state.Location)

    useEffect(() => {
        if (submitted && !locationRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [locationRedux.isButtonLoading])

    useEffect(() => {
        const locationInfo = locationRedux.locations.filter(location => location.id === params.locationId).pop()

        if (locationInfo?.id) {
            setLocation(locationInfo)
        } else {
            dispatch(getLocations({ companyId: params.companyId }))
        }
    }, [locationRedux.locations])

    const submitLocationForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)


        setSubmittedCount(submittedCount + 1)

        let hasError = false
        for (const key in locationForm) {
            if (locationForm[key].isRequired && !locationForm[key].fieldValue || locationForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const name = locationForm.name.fieldValue
            const address = locationForm.address.fieldValue

            dispatch(editLocation({
                name: name,
                address: address,
                companyId: params.companyId,
                locationId: params.locationId
            }))
        }
    }

    const confirmDeleteLocation = () => {
        setSubmitted(true)
        dispatch(deleteLocation({ location: {
            ... location,
            locationId: params.locationId,
            companyId: params.companyId
        } }))
    }

    return locationRedux.isSingleLoading ? <div className="flex items-center justify-center"><div role="status">
        <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
    </div> Loading </div> : <form onSubmit={submitLocationForm}>

        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>

            <button type="button" onClick={() => setShowDelete(true)} className="inline-flex ml-2 items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>


                <span className='ml-2'>Delete</span>
            </button>
        </div>

        <QuestionDialog
                    isOpen={showDelete}
                    declineAction={() => setShowDelete(false)}
                    confirmAction={() => confirmDeleteLocation()}
                    confirmTitle={locationRedux.isButtonLoading ? 'Deleting Location...' : 'Yes, Delete it!'}
                    declineTitle="No"
                    title="Delete Location ?"
                    content="Deleting this company cannot be retrieved, would you still want to continue ?"
                />

        <TextField
            fieldName="name"
            label="Location Name"
            isSubmitted={submitted}
            defaultValue={location?.name}
            isRequired
            onChange={e => setLocationForm(locationForm => ({ ...locationForm, name: e }))}
        />

        <TextAreaField
            fieldName="address"
            label="Location Address"
            defaultValue={location?.address}
            isRequired
            isSubmitted={submitted}
            onChange={e => setLocationForm(locationForm => ({ ...locationForm, address: e }))}
        />



        <button
            type="submit"
            disabled={locationRedux.isButtonLoading}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
            {
                locationRedux.isButtonLoading ? <span className='ml-2'>Adding Location...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className='ml-2'>Add Location</span></>
            }

        </button>
    </form>
}