import { onAuthStateChanged, getAuth } from 'firebase/auth'
import { useState, useEffect } from 'react'
import './App.css';
import SideBar from './layouts/SideBar';
import { BrowserRouter } from 'react-router-dom';
import NoAuth from './routes/NoAuth'
import { useDispatch } from 'react-redux';
import { setUser as setUserSlice } from './redux/auth/authSlice'
import { getIdTokenResult } from 'firebase/auth'
function App() {

  const [user, setUser] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    const auth = getAuth()

    onAuthStateChanged( auth, user => {
      if (user) {
        setUser(user)
        getIdTokenResult(user).then( res => {
          setUser({
            ...user,
            ...res
          })
          dispatch(setUserSlice({ user: {
            ...user,
            ...res
          } }))
        })
      } else {
        dispatch(setUserSlice({ user: null }))
        setUser(null)
      }
    })
  }, [])

  return (
    <BrowserRouter>
     <div className="App">

      {
        user ? <SideBar user={{ ...user}} /> : <NoAuth />
      }
    </div>
    </BrowserRouter>
  );
}

export default App;
