import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Switch } from '@headlessui/react'

import NumberField from '../../components/forms/NumberField'
import SelectField from '../../components/forms/SelectField'
import ErrorMessage from '../../components/ErrorMessage'
import Order from '../../pdfs/Order1'

import { getLocations } from '../../redux/locations/LocationSlice'
import { clearError } from '../../redux/errors/ErrorSlice'
import { addOrder, getLatestOrderNumber } from '../../redux/orders/OrderSlice'
import { getCompanyProducts } from '../../redux/companyProducts/CompanyProductsSlice'
import { getAdmins, getUsers } from '../../redux/users/userSlice'
import { SketchPicker } from 'react-color'
import OrderModal from './OrderModal'

export default function OrdersAdd() {
  const companyProductRedux = useSelector(state => state.CompanyProduct)
  const orderRedux = useSelector(state => state.Order)
  const locationRedux = useSelector(state => state.Location)
  const authRedux = useSelector(state => state.Auth)
  const companyRedux = useSelector(state => state.Company)
  const errorRedux = useSelector(state => state.Error)
  const userRedux = useSelector(state => state.User)

  const [submitted, setSubmitted] = useState(false)
  const [orderForm, setOrderForm] = useState({})
  const [enableNotif, setEnableNotif] = useState(true)
  const [selectedSingleOrder, setSelectedSingleOrder] = useState(null)

  const [products, setProducts] = useState(companyProductRedux.products)

  const navigate = useNavigate()
  const params = useParams()

  const dispatch = useDispatch()

  useEffect(() => {
    if (submitted && !orderRedux.isButtonLoading) {
      navigate(-1)
    }
  }, [orderRedux.isButtonLoading])

  useEffect(() => {
    dispatch(getLatestOrderNumber())
    dispatch(getAdmins())
    dispatch(clearError())
  }, [])

  useEffect(() => {
    const sanitizedProducts = companyProductRedux.products.map(el => {
      return { ...el, quantity: 0, totalPrice: 0 }
    })

    setProducts(sanitizedProducts)
  }, [companyProductRedux.products])

  useEffect(() => {
    if (authRedux.user.claims.role === 0) {
      dispatch(getLocations({ companyId: orderForm?.company?.fieldValue }))
      dispatch(getCompanyProducts({ companyId: orderForm?.company?.fieldValue }))
      dispatch(getUsers({ companyId: orderForm?.company?.fieldValue }))
    }

  }, [orderForm?.company])

  const renderImage = (pictures) => {
    const picture = pictures?.filter(picture => !picture || Object.keys(picture).length === 0 ? null : picture).shift()
    return picture?.url || null
  }

  const saveOrder = () => {
    setSubmitted(true)
    let hasError = false

    for (const key in orderForm) {
      if (orderForm[key].isRequired && !orderForm[key].fieldValue || orderForm[key].error) {
        hasError = true
        break
      } else {
        hasError = false
      }
    }

    if (!hasError) {
      const orderedProduct = products.filter(prod => prod.quantity !== 0)
      const orderedProductTotalPrice = orderedProduct.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0)
      const locationId = authRedux.user.claims.role === 1 ? orderForm.location.fieldValue : params.locationId
      const buyerDetails = { user: authRedux.user.uid, company: params.companyId, ...locationId && { location: locationId } }

      dispatch(addOrder({
        order: {
          buyerDetails: authRedux.user.claims.role === 0 ? { user: authRedux.user.uid, role: authRedux.user.claims.role, company: orderForm?.company?.fieldValue, location: orderForm.location.fieldValue } : buyerDetails,
          products: orderedProduct,
          subtotal: orderedProductTotalPrice,
          status: 'pending',
          orderNumber: orderRedux.orderNumber,
          grandTotal: orderedProductTotalPrice,
        }
      }))

    }


  }

  const getEmailRecipients = () => {
    let users = []

    if (authRedux.user.claims.role === 1) {
      users = userRedux.users.filter(user => user.role === 0).map(user => user.email)
    } else if (authRedux.user.claims.role === 2) {
      users = userRedux.users.filter(user => user.role === 1 || user.role === 0).map(user => user.email)
    }

    if (authRedux.user.claims.role === 0) {
      if (enableNotif) {
        users = userRedux.users.filter(user => user.role == 1).map(user => user.email)
      }
    }

    const admins = userRedux.admins.map(admin => admin.email)
    users.unshift(authRedux.user.email)
    users = [...users, ...admins]

    return users
  }

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const openProductModal = (product) => {
    if (selectedSingleOrder) {
      setSelectedSingleOrder(null)
    } else {
      setSelectedSingleOrder(product)
    }
    console.info('prodct info is ', product)
  }


  const submitOrderForm = (ev) => {
    ev.preventDefault()
    setSubmitted(true)

    let hasError = false

    for (const key in orderForm) {
      if (orderForm[key].isRequired && !orderForm[key].fieldValue || orderForm[key].error) {
        hasError = true
        break
      } else {
        hasError = false
      }
    }

    if (!hasError) {
      const orderedProduct = products.filter(prod => prod.quantity !== 0)
      const orderedProductTotalPrice = orderedProduct.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0)
      const locationId = authRedux.user.claims.role === 1 || authRedux.user.claims.role === 0 ? orderForm.location.fieldValue : params.locationId
      const buyerDetails = { user: authRedux.user.uid, role: authRedux.user.claims.role, company: params.companyId, ...locationId && { location: locationId } }
      const companyId = authRedux.user.claims.role === 0 ? orderForm.company.fieldValue : authRedux.user.claims.company

      const locationName = locationRedux.locations.filter(loc => loc.id === locationId).pop().name
      const selectedCompany = companyRedux.companies.filter(company => company.id === companyId).pop()
      const createdDate = parseInt(Date.now() / 1000)

      dispatch(addOrder({
        order: {
          buyerDetails: authRedux.user.claims.role === 0 ? { user: authRedux.user.uid, role: authRedux.user.claims.role, company: orderForm?.company?.fieldValue, location: orderForm.location.fieldValue } : buyerDetails,
          products: orderedProduct,
          subtotal: orderedProductTotalPrice,
          status: 'submitted',
          orderNumber: orderRedux.orderNumber,
          grandTotal: orderedProductTotalPrice,
          ...enableNotif && {
            pdfString: Order.exportOrderPDF('email', orderRedux.orderNumber, orderedProduct, orderedProductTotalPrice, 0, orderedProductTotalPrice, locationName, selectedCompany.name, createdDate),
            emails: getEmailRecipients()
          }
        }
      }))
    }


  }

  const closeModal = () => {
    setSelectedSingleOrder(null)
  }

  const quantityOnChange = (ev, product) => {
    if (ev.error.trim().length == 0) {
      const quantity = ev.fieldValue
      const totalPrice = quantity * product.price

      setProducts(prevState => prevState.map(prod => prod.id === product.id ? { ...product, quantity: quantity, totalPrice: totalPrice } : prod))

    }
  }

  return <div>
    <form onSubmit={submitOrderForm}>
      <div className='flex mb-20'>
        <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

          <span className='ml-2'>Back</span>
        </button>
      </div>

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Create Order</h1>
          </div>

        </div>

        {
          authRedux?.user.claims.role == 0 ? <div className='mb-4'> <SelectField
            legend="Choose a Company"
            label="Company"
            options={companyRedux.companies.map(el => Object({ label: el.name, value: el.id }))}
            isRequired
            fieldName="company"
            isSubmitted={submitted}
            onChange={e => setOrderForm(orderForm => ({ ...orderForm, company: e }))}
          /> </div> : null
        }

        {
          authRedux.user.claims.role != 2 ? <div>
            <SelectField
              legend="Choose a location"
              label="Location"
              options={locationRedux.locations.map(el => Object({ label: el.name, value: el.id }))}
              isRequired
              fieldName="location"
              isSubmitted={submitted}
              onChange={e => setOrderForm(orderForm => ({ ...orderForm, location: e }))}
            />

          </div> : null
        }

        {
          authRedux.user.claims.role === 0 ? <Switch.Group as="div" className="flex mt-4 items-center">
            <Switch
              checked={enableNotif}
              onChange={setEnableNotif}
              className={classNames(
                enableNotif ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  enableNotif ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
              />
            </Switch>
            <Switch.Label as="span" className="ml-3 cursor-pointer">
              <span className="text-sm font-medium text-gray-900">Enable Notification</span>
            </Switch.Label>
          </Switch.Group> : null
        }

        <div className='w-80 flex justify-start mt-10'>
          <p className='text-lg'> Order #: <span className='text-indigo-600'> {orderRedux.orderNumber} </span></p>
        </div>
        <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                >
                  Product Info
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Price
                </th>
                <th
                  scope="col"
                  className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                >
                  Subtotal
                </th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id} className="border-b border-gray-200">
                  <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">

                    <div className="font-medium text-gray-900 flex">
                      <div className='mr-2'>
                        {
                          renderImage(product?.pictures) ? <img className="h-10 w-10 object-center object-fit rounded-full" src={renderImage(product?.pictures)} alt="" /> :
                            <img className="h-10 w-10 object-center object-fit rounded-full" src="../../../images/placeholder.png" alt="" />
                        }
                      </div>
                      <p className='text-indigo-600 cursor-pointer' onClick={() => openProductModal(product)}> {product.title} </p>

                    </div>
                    <div className="mt-0.5 text-gray-500 sm:hidden">
                      {product.price}
                    </div>
                    {
                        product && product.tags ? <div className="flex mt-4">
                          {
                            product && product.tags?.map((tag, index) => <div
                            style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})`}}
                            class="text-white mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                            >
                              {tag.name}
                            </div>)
                          }

                        </div> : null
                      }
                  </td>
                  <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">${product.price}</td>
                  <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
                    <span className='flex items-center justify-end'>
                      <NumberField
                        name="quantity"
                        customWidth={80}
                        onChange={e => quantityOnChange(e, product)}
                      />
                    </span>
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${product?.totalPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                >
                  Subtotal
                </th>
                <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Subtotal
                </th>
                <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${products.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                >
                  Delivery Fee
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                  Delivery Fee
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">TBD</td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colSpan={3}
                  className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                >
                  Total
                </th>
                <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                  ${products.reduce((accum, current) => Number(accum) + Number(current.totalPrice), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </td>
              </tr>
            </tfoot>
          </table>
          {errorRedux.errorMessage ? <div className='py-3'> <ErrorMessage message={errorRedux.errorMessage} /> </div> : null}


          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              onClick={() => saveOrder()}
              className="mr-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {
                orderRedux.isButtonLoading ? <span> Saving Order... </span> : <span> Save Order</span>
              }

            </button>

            <button
              type="submit"
              disabled={orderRedux.isButtonLoading}
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            >
              {
                orderRedux.isButtonLoading ? <span> Placing Order...</span> : <span> Place Order</span>
              }
            </button>


          </div>
          <OrderModal
            isOpen={selectedSingleOrder ? true : false}
            productInfo={selectedSingleOrder}
            closeModal={closeModal}
          />
        </div>
      </div>
    </form>
  </div>
}