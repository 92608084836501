import { useEffect, useState } from "react"
import NumberField from "../components/forms/NumberField"

import { useDispatch, useSelector } from "react-redux"
import { deleteCompanyProduct, editCompanyProduct, getCompanyProducts } from '../redux/companyProducts/CompanyProductsSlice'
import { useParams, useNavigate } from "react-router-dom"

export default function CompanyProductSingle() {
    const [selectedProduct, setSelectedProduct] = useState({})
    const [productForm, setProductForm] = useState(null)
    const [submitted, setSubmitted] = useState(false)

    const productRedux = useSelector(state => state.Product)
    const authRedux = useSelector(state => state.Auth)
    const companyProductRedux = useSelector(state => state.CompanyProduct)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {
        if (submitted && !companyProductRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [companyProductRedux.isButtonLoading])

    useEffect(() => {
        const productInfo = companyProductRedux.products.filter(product => product.id === params.productId).pop()

        console.info('product info', productInfo)

        if (productInfo?.id) {
            setSelectedProduct(productInfo)
        } else {
            dispatch(getCompanyProducts({ companyId: params.companyId }))
        }
    }, [companyProductRedux.products])


    const submitProductForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        let hasError = false
        for (const key in productForm) {
            if (productForm[key].isRequired && !productForm[key].fieldValue || productForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const price = productForm.price.fieldValue

            const product = {
                ...selectedProduct,
                price: price,
                companyId: params.companyId,
                productId: params.productId
            }

            dispatch(editCompanyProduct({ product }))
        }
    }

    const removeProduct = () => {
        setSubmitted(true)
        dispatch(deleteCompanyProduct({
            companyId: params.companyId,
            productId: params.productId,
        }))
    }

    const renderImage = (pictures) => {
        const picture = pictures?.filter(picture => !picture || Object.keys(picture).length === 0 ? null : picture).shift()
        return picture?.url || null
    }

    return <div>
        <div className='flex mb-10'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>

        {
            authRedux.user.claims.role === 1 ? <div className="flex w-full">
                <div className="flex h-full w-full flex-col  bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">

                        <div className="mt-8">
                            <div className="">
                                <ul role="list" className="divide-gray-200">
                                    <li key={selectedProduct.id} className="flex py-6">
                                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                                            {
                                                renderImage(selectedProduct?.pictures) ? <img className="h-24 w-24 object-center object-fit object-contain rounded-full" src={renderImage(selectedProduct?.pictures)} alt="" /> :
                                                    <img className="h-24 w-24 object-center object-fit object-contain rounded-full" src="../../../images/placeholder.png" alt="" />
                                            }
                                        </div>

                                        <div className="ml-4 flex flex-1 flex-col border-t border-gray-300 pt-2">
                                            <div>
                                                <div className="flex justify-between text-base font-medium text-gray-900">
                                                    <h3>
                                                        <a>{selectedProduct.title}</a>
                                                    </h3>
                                                </div>

                                                <div className="flex pb-5 mt-4">
                                                    {
                                                        selectedProduct && selectedProduct.tags?.map((tag, index) => <div
                                                        style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})`}}
                                                        class="mr-4 text-white text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                                                        >
                                                            {tag.name}
                                                        </div>)
                                                    }

                                                </div>


                                                <p className="mt-1 text-sm text-justify text-gray-500">{selectedProduct.description}</p>
                                            </div>
                                            <div className="flex mt-5 flex-1 items-end justify-between text-sm">
                                                <div className='flex flex-col items-start'>
                                                    <p className="text-gray-500">Price: <span className='font-bold text-black'>${selectedProduct.price?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</span></p>
                                                </div>

                                                <div className="flex">

                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div> : <div key={selectedProduct.id} className="border-2 py-2 border-indigo-600 mb-10">
                <form onSubmit={submitProductForm}>
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">

                            <div className="flex-shrink-0">
                                {
                                    renderImage(selectedProduct?.pictures) ? <img className="h-10 w-10 object-center object-fit rounded-full" src={renderImage(selectedProduct?.pictures)} alt="" /> :
                                        <img className="h-10 w-10 object-center object-fit rounded-full" src="../../../images/placeholder.png" alt="" />

                                }
                            </div>
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <p className="truncate text-sm font-medium text-left text-indigo-600">{selectedProduct.title}</p>
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                        <NumberField
                                            fieldName="price"
                                            label="Product Price"
                                            defaultValue={selectedProduct.price}
                                            isRequired
                                            onChange={(e) => setProductForm({ ...productForm, price: e })}
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <button type="button" onClick={() => removeProduct()}
                                className="inline-flex items-center justify-center rounded-md border-2 border-indigo-600 bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                <span className='ml-2'>Remove Product </span>
                            </button>
                        </div>
                    </div>
                    <button
                        type="submit"
                        disabled={companyProductRedux.isButtonLoading}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        {
                            companyProductRedux.isButtonLoading ? <span className='ml-2'>Saving Product...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg> <span className='ml-2'>Save Product</span></>
                        }
                    </button>
                </form>
            </div>
        }
    </div>
}