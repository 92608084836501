import { useEffect, useState } from "react"
import TextField from "../components/forms/TextField"
import TextAreaField from "../components/forms/TextAreaField"
import { useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { addLocation } from "../redux/locations/LocationSlice"

export default function LocationAdd() {
    const [submitted, setSubmitted] = useState(false)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [locationForm, setLocationForm] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const locationRedux = useSelector(state => state.Location)


    useEffect(() => {
        if (submitted && !locationRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [locationRedux.isButtonLoading])

    const submitLocationForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)


        setSubmittedCount(submittedCount + 1)

        let hasError = false
        for (const key in locationForm) {
            if (locationForm[key].isRequired && !locationForm[key].fieldValue || locationForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const name = locationForm.name.fieldValue
            const address = locationForm.address.fieldValue

            dispatch(addLocation({
                name: name,
                address: address,
                companyId: params.id
            }))
        }
    }


    return <form onSubmit={submitLocationForm}>

        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>



        <TextField
            fieldName="name"
            label="Location Name"
            isSubmitted={submitted}
            isRequired
            onChange={e => setLocationForm(locationForm => ({ ...locationForm, name: e }))}
        />

        <TextAreaField
            fieldName="address"
            label="Location Address"
            isRequired
            isSubmitted={submitted}
            onChange={e => setLocationForm(locationForm => ({ ...locationForm, address: e }))}
        />

        

        <button
            type="submit"
            disabled={locationRedux.isButtonLoading}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
            {
                locationRedux.isButtonLoading ? <span className='ml-2'>Adding Location...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className='ml-2'>Add Location</span></>
            }

        </button>
    </form>
}