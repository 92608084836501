import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isSingleLoading: false,
    isButtonLoading: false,
    companyId: '',
    orders: [],
    orderNumber: 0,
}

const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        getOrders (state, action) {
            state.isListLoading = true
            state.companyId = action.payload.companyId
        },
        getOrdersSaga (state, action) {
            state.orders = action.payload.orders
            state.isListLoading = false
        },
        getLatestOrderNumber (state) {

        },
        getLatestOrderNumberSaga (state, action) {
            state.orderNumber = action.payload.orderNumber
        },
        getSingleOrder (state) {
            state.isSingleLoading = true
        },
        getSingleOrderSaga (state, action) {
            state.locations = [action.payload.location, ...state.locations]
            state.isSingleLoading = false
        },
        addOrder (state) {
            state.isButtonLoading = true
        },
        addOrderSaga (state, action) {
            state.orders.unshift(action.payload.order)
            state.isButtonLoading = false
        },
        hasError (state) {
            state.isButtonLoading = false
            state.isListLoading = false
            state.isSingleLoading = false
        },
        deleteOrder (state) {
            state.isButtonLoading = true
        },
        deleteOrderSaga (state, action) {
            state.orders = state.orders.filter(order => order.id !== action.payload.orderId ? order : null)
            state.isButtonLoading = false
        },
        editOrder (state) {
            state.isButtonLoading = true
        },
        editOrderSaga (state, action) {
            state.orders = state.orders.map(order => order.id !== action.payload.order.id ? order : action.payload.order )
            state.isButtonLoading = false
        }

    }
})

export const {
    getOrders,
    getOrdersSaga,
    getSingleOrder,
    getSingleOrderSaga,
    addOrder,
    addOrderSaga,
    hasError,
    deleteOrder,
    deleteOrderSaga,
    editOrder,
    editOrderSaga,
    getLatestOrderNumber,
    getLatestOrderNumberSaga
} = orderSlice.actions

export default orderSlice.reducer