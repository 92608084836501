import jsPDFInvoiceTemplate, { OutputType } from "jspdf-invoice-template";
import moment from 'moment'

const pricifyNumber = (number) => {
    return Number(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const exportOrderPDF = (type, orderNumber, products = [], subtotal, delivery, grandtotal, location, company, dateOrdered) => {
    const props = {
        outputType: type !== 'email' ? OutputType.Save : OutputType.DataUriString,
        returnJsPDFDocObject: true,
        fileName: `Order ${orderNumber}`,
        orientationLandscape: false,
        compress: true,
  
        business: {
            name: 'Spice Is Nice Inc.',
            address: "2335 Ducoma Dr NW, Grand Rapids MI, 49504",
            phone: "248-634-3397",
            email: "sales@bestseasoningsever.com",
            email_1: "https://bestseasoningsever.com"
        },
        contact: {
            label: "Order issued for:",
            name: company,
            address: location,
            phone: " ",
            email: " ",
            other_info: " "
        },
        invoice: {
            label: "Order #:",
            num: orderNumber,
            invDate: moment.unix(dateOrdered).format('MMMM DD, YYYY'),
            header: [
                {
                    title: 'PRODUCT', 
                    style: { width: 120 }
                },{
                    title: "QTY",
                }, {
                    title: "UNIT PRICE",
                }, {
                    title: "AMOUNT",
                }
            ],
            table: Array.from(products, (product) => ([
                `${product.title} \n`, product.quantity, `$${pricifyNumber(product.price)}`, `$${pricifyNumber(product.totalPrice)}`
            ])),
            additionalRows: [{
                col1: 'Subtotal:',
                col2: `$${pricifyNumber(subtotal)}`,
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'Shipping:',
                col2: `$${ delivery ? pricifyNumber(delivery) : 'TBD'}`,
                style: {
                    fontSize: 10 //optional, default 12
                }
            },
            {
                col1: 'Total:',
                col2: `$${pricifyNumber(grandtotal)}`,
                style: {
                    fontSize: 14//optional, default 12
                }
            }],
        },
    
    }

    const createdPdf = jsPDFInvoiceTemplate(props)
    const doc = createdPdf.jsPDFDocObject
    if (type === 'email') {
        return doc.output('datauristring')
    }
}

export default {
    exportOrderPDF
}