import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"
import { getCompanyProducts, addCompanyProduct } from "../redux/companyProducts/CompanyProductsSlice"

export default function CompanyProductsAdd() {
    const [submitted, setSubmitted] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const productRedux = useSelector(state => state.Product)
    const companyProductRedux = useSelector(state => state.CompanyProduct)


    useEffect(() => {
        if (companyProductRedux.products.length === 0 && companyProductRedux.companyId !== params.id) {
            dispatch(getCompanyProducts({ companyId: params.id }))
        }
    }, [])

    useEffect(() => {
        if (submitted && !productRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [productRedux?.isButtonLoading])


    const renderImage = (pictures) => {
        const picture = pictures?.filter(picture => !picture || Object.keys(picture).length === 0 ? null : picture).shift()
        return picture?.url || null
    }

    const submitProductForm = (ev, product) => {
        ev.preventDefault()
        setSubmitted(true)


        dispatch(addCompanyProduct({
            ...product,
            companyId: params.id,
        }))
    }

    const checkCompanyProductExist = (product) => {
        const isExist = companyProductRedux.products.filter(el => el.id === product.id ? product : null).length
        return isExist
    }

    return <>
        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>
        <div className="overflow-hidden bg-white mb-10 shadow sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
                {productRedux.products.map((product) => (
                    <form onSubmit={(ev) => submitProductForm(ev, product)}>
                        <li key={product.id} >
                            <a href={product.href} className="block hover:bg-gray-50">
                                <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="flex min-w-0 flex-1 items-center">
                                        <div className="flex-shrink-0">
                                        {
                                         renderImage(product?.pictures) ? <img className="h-10 w-10 object-center object-fit rounded-full" src={renderImage(product?.pictures)} alt="" />  : 
                                         <img className="h-10 w-10 object-center object-fit rounded-full" src="../../../images/placeholder.png" alt="" /> 
                                  }
                                        </div>
                                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <p className="truncate text-sm font-medium text-left text-indigo-600">{product.title}</p>
                                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                                    <span className="truncate">${product.price}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            checkCompanyProductExist(product) ? <p className="text-red-600"> Product Exist</p> : <button type="submit"
                                                disabled={companyProductRedux.isButtonLoading}
                                                className="inline-flex items-center justify-center rounded-md border-2 border-indigo-600 bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                                                    {
                                                        companyProductRedux.isButtonLoading ? <span className='ml-2'>Adding Product...</span> :<>   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    <span className='ml-2'>Add Product </span> </>
                                                    }
                                              
                                            </button>
                                        }

                                    </div>
                                </div>
                            </a>
                        </li>
                    </form>
                ))}
            </ul>
        </div>

    </>
}