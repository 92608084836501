import { useState, useEffect } from "react"

export default function EmailField({ fieldName, label, onChange, isSubmitted, isRequired, defaultValue }){

    const [textValue , setTextValue] = useState('')
    const [errorValue, setErrorValue] = useState('')
    

    useEffect(() => {
        checkEmail()
        checkRequired()

        onChange({
            fieldValue: textValue,
            fieldName: fieldName,
            error: errorValue,
            isSubmitted: isSubmitted,
            isRequired: isRequired
        })
    }, [textValue, isSubmitted, errorValue, isRequired])

    useEffect(() => {
        if (defaultValue) {
            setTextValue(defaultValue)
        }
    }, [defaultValue])

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }
    const checkRequired = () => {
        if (isSubmitted && isRequired) {
            if (textValue?.trim()?.length === 0) {
                setErrorValue(`${fieldName} is required`)
            } else {
                setErrorValue('')
            }
        }
    }

    const checkEmail = () => {
        const validEmail = /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/

        if (textValue.trim().length === 0) {
            return setErrorValue('')
        }

        if (validEmail.test(textValue)) {
            setErrorValue(``)
        } else {
            setErrorValue('Email is badly formatted')
        }
    }

    const onTextFieldChange = (ev) => {
        setTextValue(ev.target.value)
    }

    return <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
            {label}
        </label>
        <input 
            className={classNames(
                errorValue ? 'border-red-500' : '',
                'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
            )}
            type="text"
            value={textValue}
            onChange={onTextFieldChange}
             />
        {
            errorValue ? <p className="text-red-500 text-xs font-bold italic text-left"> {errorValue} </p> : null
        }
        
    </div>
}