import { Routes, Route, Navigate } from "react-router-dom";

import Login from "../pages/Login"
import ForgotPassword from '../pages/ForgotPassword'

export default function NoAuth() {
    return <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />

    </Routes>
}