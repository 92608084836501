import { Routes, Route, Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Dashboard from '../pages/Dashboard'
import Orders from '../pages/Orders/Orders'
import OrderSingle from '../pages/Orders/OrderSingle'
import OrdersEdit from '../pages/Orders/OrdersEdit'
import OrdersAdd from '../pages/Orders/OrdersAdd'
import Products from '../pages/Products'
import ProductSingle from '../pages/ProductSingle'
import ProductAdd from '../pages/ProductAdd'
import ProductEdit from '../pages/ProductEdit'
import Companies from '../pages/Companies'
import CompanyAdd from '../pages/CompanyAdd'
import CompanySingle from '../pages/CompanySingle'
import CompanyEdit from '../pages/CompanyEdit'
import Locations from '../pages/Locations'
import LocationAdd from '../pages/LocationAdd'
import CompanyProducts from '../pages/CompanyProducts'
import CompanyProductAdd from '../pages/CompanyProductAdd'
import CompanyProductSingle from '../pages/CompanyProductSingle'
import LocationSingle from '../pages/LocationSingle'
import UsersList from '../pages/Users/UsersList'
import UsersAdd from '../pages/Users/UserAdd'
import UsersEdit from '../pages/Users/UsersEdit'

import { getCompanies } from '../redux/companies/CompanySlice'
import { getProducts } from '../redux/products/ProductSlice'
import { getLatestOrderNumber, getOrders } from '../redux/orders/OrderSlice'

const SuperAdmin = () => {
    const dispatch = useDispatch()
    
    useEffect(() => {
        dispatch( getCompanies() )
        dispatch( getProducts() )
        dispatch( getOrders({ companyId: null }))
        dispatch( getLatestOrderNumber() )
    }, [])

    return <Routes>
        <Route path="/" element={<Navigate to="/orders" />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/orders/add" element={<OrdersAdd />} />
        <Route path="/orders/:orderId" element={<OrderSingle />} />
        <Route path='/orders/:orderId/edit' element={<OrdersEdit />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:id" element={<ProductSingle />} />
        <Route path="/products/add" element={<ProductAdd />} />
        <Route path="/products/:id/edit" element={<ProductEdit />} />
        <Route path="/companies" element={<Companies />} />
        <Route path="/companies/add" element={<CompanyAdd />} />
        <Route path="/companies/:id" element={<CompanySingle />} />
        <Route path="/companies/:id/edit" element={<CompanyEdit />} />
        <Route path="/companies/:id/locations" element={<Locations />} />
        <Route path="/companies/:id/locations/add" element={<LocationAdd />} />
        <Route path="/companies/:companyId/locations/:locationId" element={<LocationSingle />} />
        <Route path="/companies/:companyId/products" element={<CompanyProducts />} />
        <Route path="/companies/:id/products/add" element={<CompanyProductAdd />} />
        <Route path="/companies/:companyId/products/:productId" element={<CompanyProductSingle />} />
        <Route path="/companies/:companyId/users" element={<UsersList />} />
        <Route path="/companies/:companyId/users/add" element={<UsersAdd />} />
        <Route path="/companies/:companyId/users/:userId" element={<UsersEdit />} />


        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
}

export default SuperAdmin