import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isSingleLoading: false,
    isButtonLoading: false,
    companyId: '',
    products: [],
}

const companyProductSlice = createSlice({
    name: 'companyProduct',
    initialState,
    reducers: {
        getCompanyProducts (state, action) {
            state.isListLoading = true
            state.companyId = action.payload.companyId
        },
        getCompanyProductsSaga (state, action) {
            state.products = action.payload.products
            state.isListLoading = false
        },
        getSingleCompanyProduct (state) {
            state.isSingleLoading = true
        },
        getSingleCompanyProductSaga (state, action) {
            state.products = [action.payload.product, ...state.products]
            state.isSingleLoading = false
        },
        addCompanyProduct (state) {
            state.isButtonLoading = true
        },
        addCompanyProductSaga (state, action) {
            state.products.unshift(action.payload.product)
            state.isButtonLoading = false
        },
        deleteCompanyProduct (state) {
            state.isButtonLoading = true
        },
        deleteCompanyProductSaga (state, action) {
            state.products = state.products.filter(product => product.id !== action.payload.product.productId ? product : null)
            state.isButtonLoading = false
        },
        editCompanyProduct (state) {
            state.isButtonLoading = true
        },
        editCompanyProductSaga (state, action) {
            state.products = state.products.map(product => product.id !== action.payload.product.id ? product : action.payload.product )
            state.isButtonLoading = false
        }

    }
})

export const {
    getCompanyProducts,
    getCompanyProductsSaga,
    getSingleCompanyProduct,
    getSingleCompanyProductSaga,
    addCompanyProduct,
    addCompanyProductSaga,
    deleteCompanyProduct,
    deleteCompanyProductSaga,
    editCompanyProduct,
    editCompanyProductSaga
} = companyProductSlice.actions

export default companyProductSlice.reducer