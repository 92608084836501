import { all, put, takeEvery } from 'redux-saga/effects'
import { getProducts, getProductsSaga, addProduct, editProduct, editProductSaga, addProductSaga, getSingleProduct, getSingleProductSaga, deleteProduct, deleteProductSaga } from './ProductSlice'
import { getDocuments, getSingleDocument, addDocument, uploadBytes, ref, storage, getDownloadURL, updateDocument, serverTimestamp, deleteDocument, deleteImage, getDocumentsFromGroup, getDocumentsWithWhere } from '../../firebase'

function* processGetProducts() {
    try {
        const products = yield getDocuments('products')

        yield put(getProductsSaga({ products }))
    } catch (er) {

    }
}

function* processDeleteProduct ({ payload }) {
    try {
        console.info('deleting prod ', payload)
        yield deleteDocument('products', payload.product.id)

        if (payload.product?.picture?.url) {
            yield deleteImage(payload.product.picture.storagePath)
        }

        if (payload.product?.nutrition?.url) {
            
            yield deleteImage(payload.product.nutrition.storagePath).catch(err => {
                console.info('error deleting img ', err)
            })
        }


        yield put(deleteProductSaga({ product: payload.product }))
    } catch (er) {
        console.info('err deleting prod', er)
    }
}

function* processAddProduct ({ payload }) {
    try {
        const pictures = []

        const savedProduct = yield addDocument('products', {
            title: payload.title,
            description: payload.description,
            price: payload.price,
            tags: payload.tags,
            created_date: serverTimestamp()
        })

        if (payload.pictures.length !== 0) {
            for (let i = 0; i < payload.pictures.length; i++) {
                const picture = payload.pictures[i]
                let pictureObject = {}

                if (picture?.path) {
                    const storageRef = ref(storage, `products/pictures/${savedProduct.id}/picture_${i + 1}.${picture.extension}`)

                    const uploadTask = yield uploadBytes(storageRef, picture.path)
                    const downloadableUrl = yield getDownloadURL(uploadTask.ref)
                    
                    pictureObject = {
                        storagePath: uploadTask.ref.fullPath,
                        url: downloadableUrl
                    }
                }
            
                pictures.push(pictureObject)

            }

            yield updateDocument('products', savedProduct.id,{ 
                pictures: pictures,
            })
        }

        const product = {
            ... payload,
            ... pictures.length !== 0 && {
                pictures: pictures
            },
            id: savedProduct.id,
            created_date: {
                seconds: parseInt(Date.now()/1000)
            },
        }

        yield put(addProductSaga({ product }))
    } catch (er) {
        console.info('error in adding to firestore ', er)
    }
}

function* processGetSingleProduct({ payload }) {
    try {
      const product = yield getSingleDocument('products', payload.productId)

      yield put( getSingleProductSaga({ product }))
    } catch (er) {

    }
}

function* processEditProduct ({ payload }) {
    try {
        const pictures = []

        yield updateDocument('products', payload.id, {
            title: payload.title,
            description: payload.description,
            price: payload.price,
            tags: payload.tags || []
        })

        const products = yield getDocumentsFromGroup('products','id','==', payload.id)

        for (let i = 0; i < products.length; i++ ) {
            const product = products[i]
            const productId = product.documentPath.split('/').pop()
            const collectionPath = product.documentPath.replace(`/${productId}`,'')

            yield updateDocument(collectionPath, productId, {
                tags: payload.tags || []
            })
        }

        const existingOrders = yield getDocumentsWithWhere('orders','products','!=', [])

        for (let i = 0; i < existingOrders.length; i++) {
            const products = existingOrders[i].products
            const singleOrder = existingOrders[i]

            for (let j = 0; j < products.length; j++) {
                const product = products[j]
                if (product.id === payload.id) {
                    const updatedProducts = products.map( product => product.id === payload.id ? {...product, tags: payload.tags} : product )
                    
                    yield updateDocument('orders', singleOrder.id, {
                        products: updatedProducts
                    })
                }
            }
        }


        if (payload.pictures.length !== 0) {
            for (let i = 0; i < payload.pictures.length; i++) {
                const picture = payload.pictures[i]
                let pictureObject = {}

                // console.info('picture is ', payload.pictures[i], ' poicture lent ', payload.pictures.length, 'index is ', i)
                // for only removing the image
                if (payload.existingPictures[i]?.storagePath && !picture) {
                        console.info('delete only ', picture, ' with the storage path of existing image ', payload.existingPictures[i]?.storagePath)
                        yield deleteImage(payload.existingPictures[i].storagePath)
                        pictureObject = {}
                } else

                // for adding the image
                if (picture?.path) {
                    const isPictureIndexExist = payload.existingPictures[i]

                    // for adding the image with existing image at the specific index
                    if (isPictureIndexExist?.storagePath) {
                        yield deleteImage(isPictureIndexExist.storagePath)
                    }

                    const storageRef = ref(storage, `products/pictures/${payload.id}/picture_${i + 1}.${picture.extension}`)

                    const uploadTask = yield uploadBytes(storageRef, picture.path)
                    const downloadableUrl = yield getDownloadURL(uploadTask.ref)
                    
                    pictureObject = {
                        storagePath: uploadTask.ref.fullPath,
                        url: downloadableUrl
                    }
                } else {
                    if ( !payload.existingPictures[i] || Object.keys(payload.existingPictures[i]).length === 0 ) {
                        pictureObject = {}
                    } else {
                        pictureObject = {
                            storagePath: payload.existingPictures[i].storagePath,
                            url: payload.existingPictures[i].url
                        }
                    }
                    
                }

                pictures.push(pictureObject)

            }
            
            yield updateDocument('products', payload.id,{ 
                pictures: pictures,
            })
        }

        const pictureObject = {
            ... payload,
            pictures: pictures
        }


        yield put( editProductSaga({ product: pictureObject }))

    } catch (er) {
        console.info('error while editing prod ', er)
    }
}

export function* watchProcesses() {
    yield takeEvery(getProducts().type, processGetProducts)
    yield takeEvery(getSingleProduct().type, processGetSingleProduct)
    yield takeEvery(addProduct().type, processAddProduct)
    yield takeEvery(deleteProduct().type, processDeleteProduct)
    yield takeEvery(editProduct().type, processEditProduct)
}

function* productSaga() {
    yield all([ watchProcesses() ])
}

export default productSaga