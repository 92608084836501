import { all, put, takeEvery } from 'redux-saga/effects'
import { addDocument, getDocuments, serverTimestamp, updateDocument, getSingleDocument, setDocument, deleteDocument } from '../../firebase'
import { getCompanyProducts, getCompanyProductsSaga, addCompanyProduct, addCompanyProductSaga, editCompanyProduct, editCompanyProductSaga, getSingleCompanyProduct, getSingleCompanyProductSaga, deleteCompanyProduct, deleteCompanyProductSaga } from './CompanyProductsSlice'

function* processGetCompanyProducts({ payload }) {
    try {
      const products = yield getDocuments(`companies/${payload.companyId}/products`)

      yield put( getCompanyProductsSaga({ products }))
    } catch (er) {
        console.info('error fetching location ', er)
    }
}

function* processAddCompanyProduct ({ payload }) {
    try {
        yield setDocument(`companies/${payload.companyId}/products`, payload.id, {...payload})


        const product = {
            ... payload,
            created_date: {
                seconds: parseInt(Date.now()/1000)
            }
        }


        yield put( addCompanyProductSaga({ product }) )
    } catch (er) {
        console.info('error adding co', er)
    }
}

function* processEditCompanyProduct ({ payload }) {
    try {
        yield updateDocument(`companies/${payload.product.companyId}/products`, payload.product.productId, {
            ...payload.product,
            price: payload.product.price
        })

        yield put( editCompanyProductSaga({ product: payload.product }))
        
    } catch (er){
        console.info('error is ', er)
    }
}

function* processGetSingleCompanyProduct ({ payload }) {
    try {
       const product = yield getSingleDocument(`companies/${payload.companyId}/products`, payload.productId)

       yield put( getSingleCompanyProductSaga({ product }))
    } catch (er){
    }
}

function* processDeleteCompanyProduct ({ payload }) {
    try {
        yield deleteDocument(`companies/${payload.companyId}/products`, payload.productId)

        yield put( deleteCompanyProductSaga({ product: payload }))
    } catch (er){
        console.info('ddeltng comprpodt', er)
    }
}

export function* watchProcesses() {
    yield takeEvery(getCompanyProducts().type, processGetCompanyProducts)
    yield takeEvery(addCompanyProduct().type, processAddCompanyProduct)
    yield takeEvery(getSingleCompanyProduct().type, processGetSingleCompanyProduct)
    yield takeEvery(editCompanyProduct().type, processEditCompanyProduct)
    yield takeEvery(deleteCompanyProduct().type, processDeleteCompanyProduct)
}

function* companyProductSaga() {
    yield all([ watchProcesses() ])
}

export default companyProductSaga   