import { all, put, takeEvery } from 'redux-saga/effects'
import { getCompanies, getCompaniesSaga, addCompanySaga, addCompany, getSingleCompany, getSingleCompanySaga, editCompany, editCompanySaga, deleteCompany, deleteCompanySaga } from './CompanySlice'
import { addDocument, getDocuments, serverTimestamp, ref, storage, uploadBytes, getDownloadURL, updateDocument, getSingleDocument, deleteImage, deleteDocument } from '../../firebase'

function* processGetCompanies() {
    try {
      const companies = yield getDocuments('companies')

      yield put( getCompaniesSaga({ companies }))
    } catch (er) {
    }
}

function*  processAddCompany ({ payload }) {
    try {
        let logo = ''
        const savedCompany = yield addDocument('companies', {
            name: payload.name,
            created_date: serverTimestamp()
        })

        if (payload.logo?.path) {
            const storageRef = ref(storage, `companies/logo/${savedCompany.id}`)
            const uploadTask = yield uploadBytes(storageRef, payload.logo.path)
            const downloadableUrl = yield getDownloadURL(uploadTask.ref)

            logo = {
                storagePath: uploadTask.ref.fullPath,
                url: downloadableUrl
            }
        }

        yield updateDocument('companies', savedCompany.id, {
            logo: logo
        })

        const company = {
            ... payload,
            id: savedCompany.id,
            logo: logo,
            created_date: {
                seconds: parseInt(Date.now()/1000)
            }
        }

        yield put( addCompanySaga({ company }) )
    } catch (er) {
        console.info('error adding co', er)
    }
}

function* processEditCompany ({ payload }) {
    try {
        let logo = ""

        yield updateDocument('companies', payload.id, {
            name: payload.name
        })

        if (payload?.existingLogo?.storagePath) {
            if (payload.logo?.path) {
                yield deleteImage(payload.existingLogo.storagePath)

                const storageRef = ref(storage, `companies/logo/${payload.id}`)
                const uploadTask = yield uploadBytes(storageRef, payload.logo.path)
                const downloadableUrl = yield getDownloadURL(uploadTask.ref)
    
                logo = {
                    storagePath: uploadTask.ref.fullPath,
                    url: downloadableUrl
                }

            } else {
                // yield deleteImage(payload.existingLogo.storagePath)
                logo = {
                    storagePath: payload.existingLogo.storagePath,
                    url: payload.existingLogo.url,
                }
            }
        } else if (payload.logo?.path) {
            const storageRef = ref(storage, `companies/logo/${payload.id}`)
            const uploadTask = yield uploadBytes(storageRef, payload.logo.path)
            const downloadableUrl = yield getDownloadURL(uploadTask.ref)

            logo = {
                storagePath: uploadTask.ref.fullPath,
                url: downloadableUrl
            }
        }

        yield updateDocument('companies', payload.id, {
            logo: logo
        })

        yield put( editCompanySaga({ company: payload }))
        
    } catch (er){}
}

function* processGetSingleCompany ({ payload }) {
    try {
       const company = yield getSingleDocument('companies', payload.companyId)

       yield put( getSingleCompanySaga({ company }))
    } catch (er){
    }
}

function* processDeleteCompany ({ payload }) {
    try {
        
        yield deleteDocument(`companies`, payload.company.id)

        yield put( deleteCompanySaga({ company: payload.company }) )
    } catch (er){
        console.info('deleting a company is ', er)
    }
}

export function* watchProcesses() {
    yield takeEvery(getCompanies().type, processGetCompanies)
    yield takeEvery(addCompany().type, processAddCompany)
    yield takeEvery(getSingleCompany().type, processGetSingleCompany)
    yield takeEvery(editCompany().type, processEditCompany)
    yield takeEvery(deleteCompany().type, processDeleteCompany)
}

function* companySaga() {
    yield all([ watchProcesses() ])
}

export default companySaga