import { Routes, Route, Navigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import Dashboard from '../pages/Dashboard'
import Orders from '../pages/Orders/Orders'
import OrdersAdd from '../pages/Orders/OrdersAdd'
import OrderSingle from '../pages/Orders/OrderSingle'
import OrdersEdit from '../pages/Orders/OrdersEdit'
import CompanyProducts from '../pages/CompanyProducts'
import CompanyProductSingle from '../pages/CompanyProductSingle'
import UsersList from '../pages/Users/UsersList'
import UsersAdd from '../pages/Users/UserAdd'
import UsersEdit from '../pages/Users/UsersEdit'

import { useDispatch } from 'react-redux'
import { getCompanyProducts } from '../redux/companyProducts/CompanyProductsSlice'
import { getLocations } from '../redux/locations/LocationSlice'
import { getLatestOrderNumber, getOrders } from '../redux/orders/OrderSlice'
import { getUsers } from '../redux/users/userSlice'
import { getSingleCompany } from '../redux/companies/CompanySlice'

const Manager = ({ companyId, user }) => {
    const dispatch = useDispatch()

    
    useEffect(() => {
        dispatch(getCompanyProducts({ companyId: companyId }))
        dispatch(getLocations({ companyId: companyId }))
        dispatch(getOrders({ companyId: companyId }))
        dispatch(getLatestOrderNumber({ }))
        dispatch(getSingleCompany({ companyId: companyId }))
        dispatch(getUsers({ companyId: companyId, role: user.claims.role }))
    }, [])

    return <Routes>
        <Route path="/" element={<Navigate to={`companies/${companyId}/orders`} />} />
        <Route path="/companies/:companyId/orders" element={<Orders />} />
        <Route path="/companies/:companyId/orders/:orderId" element={<OrderSingle />} />
        <Route path="/companies/:companyId/orders/:orderId/edit" element={<OrdersEdit />} />
        <Route path="/companies/:companyId/orders/add" element={<OrdersAdd />} />
        <Route path="/companies/:companyId/products" element={<CompanyProducts />} />
        <Route path="/companies/:companyId/products/:productId" element={<CompanyProductSingle />} />
        <Route path="/companies/:companyId/users" element={<UsersList />} />
        <Route path="/companies/:companyId/users/add" element={<UsersAdd />} />
        <Route path="/companies/:companyId/users/:userId" element={<UsersEdit />} />
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>
}

export default Manager