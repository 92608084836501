import { useEffect, useState } from "react"
import TextField from "../components/forms/TextField"
import NumberField from "../components/forms/NumberField"
import TextAreaField from "../components/forms/TextAreaField"
import ImagePickerField from "../components/forms/ImagePickerField"
import { useNavigate } from "react-router-dom"

import { addProduct } from '../redux/products/ProductSlice'
import { useDispatch, useSelector } from "react-redux"
import { addCompany } from "../redux/companies/CompanySlice"

export default function CompanyAdd() {
    const [submitted, setSubmitted] = useState(false)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [companyForm, setCompanyForm] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const companyRedux = useSelector(state => state.Company)


    useEffect(() => {
        if (submitted && !companyRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [companyRedux.isButtonLoading])

    const submitProductForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        setSubmittedCount(submittedCount + 1)

        let hasError = false
        for (const key in companyForm) {
            if (companyForm[key].isRequired && !companyForm[key].fieldValue || companyForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const name = companyForm.name.fieldValue
            const logo = companyForm.logo.fieldValue

            dispatch(addCompany({
                name: name,
                logo: logo,
            }))
        }
    }


    return <form onSubmit={submitProductForm}>

        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>

        <ImagePickerField
                fieldName="picture"
                label='Company Logo'
                isRequired
                isSubmitted={submitted}
                onChange={e => setCompanyForm(companyForm => ({...companyForm, logo: e})) }
            />

        <TextField
            fieldName="name"
            label="Company Name"
            isSubmitted={submitted}
            isRequired
            onChange={e => setCompanyForm(companyForm => ({ ...companyForm, name: e }))}
        />

        <button
            type="submit"
            disabled={companyRedux.isButtonLoading}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
            {
                companyRedux.isButtonLoading ? <span className='ml-2'>Adding Company...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className='ml-2'>Add Company</span></>
            }

        </button>
    </form>
}