import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isSingleLoading: false,
    isButtonLoading: false,
    companyId: '',
    users: [],
    admins: []
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers (state, action) {
            state.isListLoading = true
        },
        getAdmins (state, action) {
            state.isSingleLoading = true
        },
        getAdminsSaga (state, action) {
            state.admins = action.payload.admins
            state.isSingleLoading = false
        },
        getUsersSaga (state, action) {
            state.users = action.payload.users
            state.isListLoading = false
        },
        getSingleUser (state) {
            state.isSingleLoading = true
        },
        hasError (state) {
            state.isButtonLoading = false
            state.isSingleLoading = false
            state.isListLoading = false
        },
        getSingleUserSaga (state, action) {
            state.users = [action.payload.user, ...state.users]
            state.isSingleLoading = false
        },
        addUser (state) {
            state.isButtonLoading = true
        },
        addUserSaga (state, action) {
            state.users.unshift(action.payload.user)
            state.isButtonLoading = false
        },
        deleteUser (state) {
            state.isButtonLoading = true
        },
        deleteUserSaga (state, action) {
            state.users = state.users.filter(user => user.id !== action.payload.user.id ? user : null)
            state.isButtonLoading = false
        },
        editUser (state) {
            state.isButtonLoading = true
        },
        editUserSaga (state, action) {
            state.users = state.users.map(user => user.id !== action.payload.user.id ? user : action.payload.user )
            state.isButtonLoading = false
        }

    }
})

export const {
    getUsers,
    hasError,
    getAdmins,
    getAdminsSaga,
    getSingleUser,
    getSingleUserSaga,
    getUsersSaga,
    addUser,
    addUserSaga,
    deleteUser,
    deleteUserSaga,
    editUser,
    editUserSaga
} = userSlice.actions

export default userSlice.reducer