import createSagaMiddleware from 'redux-saga'
import reducers from './reducers'
import sagas from './sagas'
import { configureStore } from '@reduxjs/toolkit'

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]

// executed in .../src/index.js
export function createStore() {
  const store = configureStore({
    reducer: reducers,
    middleware: () => middlewares,
  })
  sagaMiddleware.run(sagas)
  return store
}