import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import { useNavigate } from 'react-router-dom'


const Products = () => {
  const productRedux = useSelector(state => state.Product)
  const navigate = useNavigate()

  const gotoSingleProduct = (productId) => {
    navigate(`${productId}`)
  }

  const renderImage = (pictures) => {
    const picture = pictures?.filter(picture => !picture || Object.keys(picture).length === 0 ? null : picture).shift()
    return picture?.url || null
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Products</h1>

        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => navigate(`add`)}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Add Product
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow p-3 ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {
                productRedux.isListLoading ? <div className="flex items-center justify-center"><div role="status">
                  <svg aria-hidden="true" className="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div> Loading </div> : <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        Product Info
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Price
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Date
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {productRedux.products.map((product) => (
                      <tr key={product.id} className="cursor-pointer" onClick={() => gotoSingleProduct(product.id)}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                          <div className="flex items-start">
                            <div className="h-10 w-10 flex-shrink-0">
                              {
                                renderImage(product?.pictures) ? <img className="h-10 w-10 object-center object-fit rounded-full" src={renderImage(product?.pictures)} alt="" /> :
                                  <img className="h-10 w-10 object-center object-fit rounded-full" src="images/placeholder.png" alt="" />
                              }
                              {/* <img className="h-10 w-10 object-center object-fit rounded-full" src={renderImage(product?.pictures)} alt="" /> */}
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-left text-gray-900">{product.title}</div>
                              <div className="text-gray-500 text-ellipsis overflow-hidden w-40 text-left">{product.description}</div>

                              <div className="flex py-5">
                                {
                                  product.tags && product.tags?.map((tag, index) => <div
                                    style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})` }}
                                    className="text-white mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                                  >
                                    {tag.name}
                                  </div>)
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="text-gray-900 text-left">${product.price}</div>
                          <div className="text-gray-500">{product.department}</div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-left text-gray-500">
                          {product.date}
                          {moment.unix(product.created_date?.seconds).format('MMMM DD, YYYY')}
                        </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products