import { all, put, takeEvery } from 'redux-saga/effects'
import { addDocument, getDocuments, serverTimestamp, updateDocument, getSingleDocument, deleteDocument, getDocumentsWithWhere } from '../../firebase'
import { addOrder, addOrderSaga, editOrder, deleteOrder, deleteOrderSaga, hasError , editOrderSaga, getLatestOrderNumber, getLatestOrderNumberSaga, getOrders, getOrdersSaga } from './OrderSlice'
import { setError } from '../errors/ErrorSlice'

import axios from 'axios'

function* processAddOrder({ payload }) {
    try {
        if (payload.order.products.length === 0) {
            throw { error: true , message: "Pick at least 1 product"}
        }

      const order = yield addDocument('orders', {...payload.order, created_date: serverTimestamp()})

      const orderInfo = {
        ...payload.order,
        id: order.id,
        created_date: {
            seconds: parseInt(Date.now()/1000)
        }
      }

      if (payload.order?.pdfString) {
        const pdfContent = {
            emails: payload.order.emails,
            orderId: payload.order.orderNumber,
            pdfbase64: payload.order?.pdfString.replace('data:application/pdf;filename=generated.pdf;base64,','')
        }
        yield axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/sendOrderEmail`, pdfContent)
      }

      yield put( addOrderSaga({ order: orderInfo }))
    } catch (er) {
        yield put(setError({ error: er.message }))
        yield put(hasError())
    }
}

function* processDeleteOrder({ payload }) {
    try {
        console.info('payload is ', payload)
        yield deleteDocument('orders', payload.orderId)

        yield put( deleteOrderSaga({ orderId: payload.orderId }))

    } catch (er) {
        console.info('error deleting order', er)
    }
}

function* processEditOrder ({ payload }) {
    try {

        yield updateDocument('orders', payload.order.id, {
            buyerDetails: payload.order.buyerDetails,
            products: payload.order.products,
            subtotal: payload.order.subtotal,
            status: payload.order.status,
            ... payload.order.delivery && {
                delivery: payload.order.delivery,
            },
            grandTotal: payload.order.grandTotal
            
        })

        const orderUpdated = {
            ...payload.order
        }

        yield put(editOrderSaga({ order: orderUpdated }))

    } catch (er){
        console.info('error editing order', er)
    }
}

function* processGetLatestOrderNumber () {
    try {
        const orders = yield getDocuments('orders','orderNumber','desc')

        if (orders.length === 0) {
            yield put(getLatestOrderNumberSaga({ orderNumber: 1000 }))
        } else {
            const latestOrder = orders[0]
            const latestOrderNumber = latestOrder.orderNumber + 1

            yield put(getLatestOrderNumberSaga({ orderNumber: latestOrderNumber }))
        }
    } catch (er) {}
}

function* processGetOrders({ payload }) {
    try {
        let orders = ''


        if (payload.companyId) {
            orders = yield getDocumentsWithWhere('orders','buyerDetails.company','==', payload.companyId,'created_date','desc')
        } else {
            orders = yield getDocuments('orders','created_date','desc')
        }
        
        if (payload.role === 2) {

            orders = orders.filter(e => e.buyerDetails.location == payload.locationId)
        }

      yield put( getOrdersSaga({ orders }))
    } catch (er) {
        console.info('error adding order ', er)
    }
}

export function* watchProcesses() {
    yield takeEvery(addOrder().type, processAddOrder)
    yield takeEvery(getOrders().type, processGetOrders)
    yield takeEvery(getLatestOrderNumber().type, processGetLatestOrderNumber)
    yield takeEvery(editOrder().type, processEditOrder)
    yield takeEvery(deleteOrder().type, processDeleteOrder)
}

function* orderSaga() {
    yield all([ watchProcesses() ])
}

export default orderSaga