import { all, put, takeEvery } from 'redux-saga/effects'
import { addDocument, getDocuments, serverTimestamp, updateDocument, getSingleDocument, deleteDocument } from '../../firebase'
import { editLocation, getLocations, getLocationsSaga, addLocationSaga, addLocation, getSingleLocation,  getSingleLocationSaga, editLocationSaga, deleteLocation, deleteLocationSaga } from './LocationSlice'

function* processGetLocations({ payload }) {
    try {
      const locations = yield getDocuments(`companies/${payload.companyId}/locations`)

      yield put( getLocationsSaga({ locations }))
    } catch (er) {
        console.info('error fetching location ', er)
    }
}

function*  processAddLocation ({ payload }) {
    try {

        const savedLocation = yield addDocument(`companies/${payload.companyId}/locations`, {
            name: payload.name,
            address: payload.address,
            created_date: serverTimestamp(),
            company: payload.companyId
        })

        yield updateDocument(`companies/${payload.companyId}/locations`, savedLocation.id, {
            id: savedLocation.id
        })


        const location = {
            ... payload,
            id: savedLocation.id,
            created_date: {
                seconds: parseInt(Date.now()/1000)
            }
        }

        yield put( addLocationSaga({ location }) )
    } catch (er) {
        console.info('error adding co', er)
    }
}

function* processEditLocation ({ payload }) {
    try {
        yield updateDocument(`companies/${payload.companyId}/locations`, payload.locationId, {
            name: payload.name,
            address: payload.address
        })

        yield put( editLocationSaga({ location: payload }))
        
    } catch (er){}
}

function* processDeleteLocation ({ payload }) {
    try {
        yield deleteDocument(`companies/${payload.location.companyId}/locations`, payload.location.locationId)

        yield put(deleteLocationSaga({ location: payload.location }))
    } catch (er) {
        console.info('deleting loc err', er)
    }
}

function* processGetSingleLocation ({ payload }) {
    try {
       const location = yield getSingleDocument(`companies/${payload.companyId}/locations`, payload.locationId)

       yield put( getSingleLocationSaga({ location }))
    } catch (er){
        console.info('err deleting location', er)
    }
}

export function* watchProcesses() {
    yield takeEvery(getLocations().type, processGetLocations)
    yield takeEvery(addLocation().type, processAddLocation)
    yield takeEvery(getSingleLocation().type, processGetSingleLocation)
    yield takeEvery(editLocation().type, processEditLocation)
    yield takeEvery(deleteLocation().type, processDeleteLocation)
}

function* locationSaga() {
    yield all([ watchProcesses() ])
}

export default locationSaga