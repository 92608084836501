import { useEffect } from 'react'
import { useState } from 'react'

export default function SelectField({
    legend,
    options,
    label,
    fieldName,
    onChange,
    isSubmitted,
    defaultValue,
    isRequired
}) {

    const [selectedOption, setSelectedOption] = useState(null)
    const [errorValue, setErrorValue] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setSelectedOption(defaultValue)
        }
    }, [defaultValue])

    useEffect(() => {
        checkRequired()

        onChange({
            fieldValue: selectedOption,
            fieldName: fieldName,
            error: errorValue,
            isSubmitted: isSubmitted,
            isRequired: isRequired,
            fieldValueLabel: options[selectedOption]?.label
        })
    }, [selectedOption, isSubmitted, errorValue, isRequired])

    const onDropdownChange = (e) => {
        setSelectedOption(e.target.value)
    }
    
    const checkRequired = () => {
        if (isSubmitted && isRequired) {
            if (!selectedOption) {
                setErrorValue(`${fieldName} is required`)
            } else {
                setErrorValue('')
            }
        }
    }

    return <div className="mb-1">

        <label for="custom-select" class="block mb-2 text-sm font-medium text-left">{label}</label>
        <select 
            id="custom-select"
            value={selectedOption}
            onChange={e => onDropdownChange(e)}
             class="bg-gray-50 border border-gray-3 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            <option selected value=''>{legend}</option>
            {
                options.map( option => 
                    <option value={option.value}> {option.label} </option>
                )
            }
        </select>
        {
            errorValue ?  <div className='text-red-500 text-xs font-bold italic text-left w-full p-2'> {errorValue}</div> : null
        }
       
    </div>
}