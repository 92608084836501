import { initializeApp, getApp} from 'firebase/app'
import { getFirestore, collection, doc, addDoc, getDocs, where, getDoc, orderBy, limit, query, serverTimestamp, updateDoc, deleteDoc, setDoc, collectionGroup } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL, uploadString, deleteObject, uploadBytes } from 'firebase/storage'
import { sendPasswordResetEmail, onAuthStateChanged, signInWithEmailAndPassword, getAuth, signOut } from 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBSAE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
}


const app = initializeApp(firebaseConfig)

const db = getFirestore(app)
const storage = getStorage(app)
const auth = getAuth(app)

const initializeFirebase = () => {
    return app
}

const loginUser = async (email, password) => {
   const userCredential = await signInWithEmailAndPassword(auth, email, password)

   if (userCredential.user) {
    return userCredential.user
   }
}

const logoutUser = async () => {
    return await signOut(auth)
}


const getDocumentsFromGroup = async (path, whereField, whereOperator, whereValue) => {
    const documents = []
    const queryString = query(collectionGroup(db, path), where(whereField, whereOperator, whereValue))
    const querySnapshot = await getDocs(queryString)
    
    querySnapshot.forEach( doc => {
        documents.push({
            id: doc.id,
            documentPath: doc.ref.path,
            ...doc.data()
        })
    })
    return documents
}

const getDocumentsWithWhere = async (collectionPath, whereField, whereOperator, whereValue, orderByField, orderByCondition) => {
    const collectionRef = collection(db, collectionPath)
    const documents = []
    let queryString = ""

    if ( orderByField) {
        queryString = query(collectionRef, where(whereField, whereOperator, whereValue), orderBy(orderByField, orderByCondition))
    } else {
        queryString = query(collectionRef, where(whereField, whereOperator, whereValue))
    }
 
    const querySnapshot = await getDocs(queryString)

    querySnapshot.forEach( doc => {
        documents.push({ id: doc.id, ...doc.data()})
    })

    return documents
}


const deleteImage = async (path) => {
    const deleteRef = ref(storage, path)
    return await deleteObject(deleteRef)
}

const addDocument = async (collectionPath, doc) => {
    return await addDoc(collection(db, collectionPath), doc)
}

const setDocument = async (collectionPath, documentId, data) => {
    return await setDoc(doc(db,collectionPath,documentId), data)
}

const updateDocument = async (collectionPath, documentId, data) => {
    const documentRef = doc(db, collectionPath, documentId)

    return await updateDoc(documentRef, data)
}

const deleteDocument = async (collectionPath, documentId) => {
    await deleteDoc(doc(db, collectionPath, documentId))
}

const getDocuments = async (path, orderByField, orderByCondition) => {
    const documents = [];
    const collectionRef = collection(db, path)
    let queryString = ''

    if (orderByField) {
        queryString = query(collectionRef, orderBy(orderByField, orderByCondition))
    } else {
        queryString = query(collectionRef)
    }

    const querySnapshot = await getDocs(queryString)

    querySnapshot.forEach( doc => {
        documents.push({ id: doc.id, ...doc.data()})
    })

    return documents
}


const getSingleDocument = async (path, id) => {
    const docRef = doc(db, path, id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        return {id: docSnap.id, ...docSnap.data()}
    } else {
        throw Error('No such document')
    }
}

export {
    db,
    storage,
    ref,
    uploadString,
    loginUser,
    logoutUser,
    setDocument,
    uploadBytes,
    deleteImage,
    getDocumentsWithWhere,
    getDocumentsFromGroup,
    getDownloadURL,
    deleteDocument,
    initializeFirebase,
    serverTimestamp,
    addDocument,
    updateDocument,
    getSingleDocument,
    getDocuments
}