import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    errorMessage: '',
}

const authSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        setError (state, action) {
            state.errorMessage = action.payload.error
        },
        clearError (state) {
            state.errorMessage = ""
        }
    }
})

export const {
    setError,
    clearError
} = authSlice.actions

export default authSlice.reducer