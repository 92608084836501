import { useEffect, useState } from "react"
import TextField from "../components/forms/TextField"
import NumberField from "../components/forms/NumberField"
import TextAreaField from "../components/forms/TextAreaField"
import ImagePickerField from "../components/forms/ImagePickerField"
import { useNavigate } from "react-router-dom"

import { addProduct } from '../redux/products/ProductSlice'
import { useDispatch, useSelector } from "react-redux"
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

export default function ProductAdd() {
    const [submitted, setSubmitted] = useState(false)
    const [submittedCount, setSubmittedCount] = useState(0)
    const [productForm, setProductForm] = useState(null)
    const [pictures, setPictures] = useState([{ path: "", extension: "", url: "" }])
    const [tags, setTags] = useState([])
    const [inputTag, setInputTag] = useState('')
    const [pickedColor, setPickedColor] = useState('')
    const [showPallete, setShowPallete] = useState(false)
    const [color, setColor] = useState({
        r: '241',
        g: '112',
        b: '19',
        a: '1',
    })

    const styles = reactCSS({
        'default': {
            color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
            },
            swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    })



    const navigate = useNavigate()
    const dispatch = useDispatch()

    const productRedux = useSelector(state => state.Product)


    useEffect(() => {
        if (submitted && !productRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [productRedux.isButtonLoading])

    const removeTag = (index) => {
        setTags(prev => prev.filter((tag, i) => index == i ? null : tag))
    }

    const pickTagColor = (color) => {
        setPickedColor(color)
    }

    const palleteOnChange = (color) => {
        setColor(color.rgb)
    }

    const addTag = () => {
        const tag = inputTag.fieldValue
        console.info('inputTag is ', inputTag.fieldValue)
        if (tag?.trim()?.length !== 0) {
            const tagObj = {
                name: tag,
                bg_color: color,
            }
            setTags( prev => [tagObj, ...prev])

            console.info('clearning out inputTag',inputTag)
        }
    }


    const submitProductForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        setSubmittedCount(submittedCount + 1)

        let hasError = false
        for (const key in productForm) {
            if (productForm[key].isRequired && !productForm[key].fieldValue || productForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError) {
            const title = productForm.title.fieldValue
            const description = productForm.description.fieldValue
            const price = productForm.price.fieldValue

            dispatch(addProduct({
                title: title,
                description: description,
                price: price,
                pictures: pictures,
                tags: tags
            }))
        }
    }

    const addPicture = () => {
        if (pictures.length < 4) {
            setPictures([...pictures, { path: "", extension: '', url: "" }])
        }
    }

    const populatePicture = (index, pictureData) => {
        setPictures(prevState => prevState.map((statePicture, stateIndex) => stateIndex === index ? pictureData.fieldValue : statePicture))
    }

    return <form onSubmit={submitProductForm}>

        <div className='flex mb-20'>
            <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

                <span className='ml-2'>Back</span>
            </button>
        </div>

        {
            pictures.map((picture, index) => <ImagePickerField
                fieldName="picture"
                label={`Product Picture ${index + 1}`}
                isSubmitted={submitted}
                onChange={e => populatePicture(index, e)}
            />)
        }

        {
            pictures.length < 4 ? <button type="button" onClick={() => addPicture()} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>


                <span className='ml-2'>Add Picture</span>
            </button> : null
        }

        <TextField
            fieldName="title"
            label="Product Title"
            isSubmitted={submitted}
            isRequired
            onChange={e => setProductForm(productForm => ({ ...productForm, title: e }))}
        />

        <div>
            <div className="flex flex-row items-center">
                <div className="w-full">
                    <TextField
                        fieldName="tag"
                        defaultValue={inputTag?.fieldValue}
                        label="Product Tag"
                        isRequired
                        onChange={e => setInputTag(e)}
                    />
                </div>

                <button
                    onClick={() => addTag()}
                    type="button"
                    className="inline-flex items-center h-11 w-11 justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                    <span className=''>Add</span>
                </button>
            </div>

            <div className="flex flex-wrap">
                    <div>
                        <div style={styles.swatch} onClick={() => setShowPallete(true)}>
                            <div style={styles.color} />
                        </div>
                        {showPallete ? <div style={styles.popover}>
                            <div style={styles.cover} onClick={() => setShowPallete(false)} />
                            <SketchPicker color={color} onChange={palleteOnChange} />
                        </div> : null}

                    </div>
                </div>

            <div className="flex py-5">
                    {
                        tags && tags?.map((tag, index) => <div
                            onClick={() => removeTag(index)}
                            style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})`}}
                            className="text-white mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            {tag.name}
                        </div>)
                    }
            </div>

            <div className="flex py-5">
                {
                    tags && tags?.map((tag, index) => <div
                        onClick={() => removeTag(index)}
                        className={`${tag.bg_color} ${tag.text_color} mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer`}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        {tag.name}
                    </div>)
                }

            </div>
        </div>

        <TextAreaField
            fieldName="description"
            label="Product Description"
            isSubmitted={submitted}
            onChange={e => setProductForm(productForm => ({ ...productForm, description: e }))}
        />

        <NumberField
            fieldName="price"
            label="Product Price"
            isSubmitted={submitted}
            isRequired
            onChange={e => setProductForm(productForm => ({ ...productForm, price: e }))}
        />

        <button
            type="submit"
            disabled={productRedux.isButtonLoading}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
            {
                productRedux.isButtonLoading ? <span className='ml-2'>Adding Product...</span> : <>  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg> <span className='ml-2'>Add Product</span></>
            }

        </button>
    </form>
}