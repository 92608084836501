import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isSingleLoading: false,
    isButtonLoading: false,
    companyId: '',
    locations: [],
}

const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        getLocations (state, action) {
            state.isListLoading = true
            state.companyId = action.payload.companyId
        },
        getLocationsSaga (state, action) {
            state.locations = action.payload.locations
            state.isListLoading = false
        },
        getSingleLocation (state) {
            state.isSingleLoading = true
        },
        getSingleLocationSaga (state, action) {
            state.locations = [action.payload.location, ...state.locations]
            state.isSingleLoading = false
        },
        addLocation (state) {
            state.isButtonLoading = true
        },
        addLocationSaga (state, action) {
            state.locations.unshift(action.payload.location)
            state.isButtonLoading = false
        },
        deleteLocation (state) {
            state.isButtonLoading = true
        },
        deleteLocationSaga (state, action) {
            state.locations = state.locations.filter(location => location.id !== action.payload.location.locationId ? location : null)
            state.isButtonLoading = false
        },
        editLocation (state) {
            state.isButtonLoading = true
        },
        editLocationSaga (state, action) {
            state.locations = state.locations.map(location => location.id !== action.payload.location.locationId ? location : action.payload.location )
            state.isButtonLoading = false
        }

    }
})

export const {
    getLocations,
    getLocationsSaga,
    getSingleLocation,
    getSingleLocationSaga,
    addLocation,
    addLocationSaga,
    deleteLocation,
    deleteLocationSaga,
    editLocation,
    editLocationSaga
} = locationSlice.actions

export default locationSlice.reducer