import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { editOrder, deleteOrder } from "../../redux/orders/OrderSlice"
import { getLocations } from '../../redux/locations/LocationSlice'
import { getUsers } from '../../redux/users/userSlice'
import { getCompanies } from '../../redux/companies/CompanySlice'

import jsPDFInvoiceTemplate, { OutputType, jsPDF } from 'jspdf-invoice-template'

import Order from '../../pdfs/Order1'
import moment from "moment"

export default function OrderSingle2() {

    const [order, setOrder] = useState([])
    const [submitted, setSubmitted] = useState(false)

    const orderRedux = useSelector(state => state.Order)
    const locationRedux = useSelector(state => state.Location)
    const authRedux = useSelector(state => state.Auth)
    const companyRedux = useSelector(state => state.Company)
    const userRedux = useSelector(state => state.User)

    const params = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const order = orderRedux.orders.filter(el => el.id === params.orderId)
        setOrder(order[0])
    }, [params.orderId])

    useEffect(() => {
        if (order?.buyerDetails?.company) {
            dispatch(getLocations({ companyId: order?.buyerDetails?.company }))
            dispatch(getUsers({ companyId: order?.buyerDetails?.company }))
            dispatch(getCompanies())
        }
    }, [order?.buyerDetails?.company])

    useEffect(() => {
        if (submitted && !orderRedux.isButtonLoading) {
            navigate(-1)
        }
    }, [orderRedux.isButtonLoading])


    const exportPDF = () => {
        const selectedLocation = locationRedux.locations.filter(loc => loc.id === order.buyerDetails.location).pop()
        const selectedCompany = companyRedux.companies.filter(company => company.id === order.buyerDetails.company).pop()

        const grandTotal = Number(order.subtotal) + Number(order.delivery ? order.delivery : 0)
        Order.exportOrderPDF('print', order.orderNumber, order.products, order.subtotal, order.delivery ? order.delivery : 0, grandTotal, selectedLocation.name, selectedCompany.name, order.created_date?.seconds)
    }

    const submitOrderForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        dispatch(editOrder({
            order: {
                id: params.orderId,
                buyerDetails: order.buyerDetails,
                products: order.products,
                subtotal: order.subtotal,
                status: 'completed',
                orderNumber: order.orderNumber,
                created_date: order.created_date,
                grandTotal: order.grandTotal
            }
        }))
    }

    const showDeletePrompt = () => {
        const desicion = window.confirm('Are you sure to delete this order')
        if (desicion) {
            dispatch(deleteOrder({
                orderId: params.orderId
            }))
            navigate(-1)

        }
        console.info(desicion)
    }

    const cancelOrderForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        dispatch(editOrder({
            order: {
                id: params.orderId,
                buyerDetails: order.buyerDetails,
                products: order.products,
                subtotal: order.subtotal,
                status: 'cancelled',
                orderNumber: order.orderNumber,
                created_date: order.created_date,
                grandTotal: order.grandTotal
            }
        }))
    }


    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between sm:flex sm:items-center">
                <div>
                    <button type="button" onClick={() => navigate(-1)} className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>

                        <span className='ml-2'>Back</span>
                    </button>
                </div>

                <div>
                    {
                        authRedux.user.claims.role == 0 ? <button onClick={() => navigate('edit')} className='inline-flex mr-2 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>

                            <span className="ml-2">Edit</span>
                        </button> : null
                    }

                    {
                        order.status !== 'pending' ? <button onClick={exportPDF} className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                            </svg>
                            <span className='ml-2'> Print </span>
                        </button> : null
                    }

                    {
                        authRedux.user.claims.role == 0 ? <button onClick={showDeletePrompt} className='inline-flex ml-2 items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                            <span className='ml-2'> Delete </span>
                        </button> : null
                    }



                </div>

            </div>
            <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
                <div className="flex flex-col items-start justify-between">
                    <div className="text-lg font-medium text-gray-900">Order #: <span className='text-indigo-500 font-bold'>{order.orderNumber}</span></div>
                    <span className="text-sm capitalize">{order.status}</span>
                </div>

                <table className="min-w-full mt-8 divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                            >
                                Product Name
                            </th>
                            <th
                                scope="col"
                                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Quantity
                            </th>
                            <th
                                scope="col"
                                className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                                Unit Price
                            </th>
                            <th
                                scope="col"
                                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                            >
                                Subtotal
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order?.products?.map((product) => (
                            <tr key={product.id} className="border-b border-gray-200">
                                <td className="py-4 pl-4 pr-3 text-sm text-left sm:pl-6 md:pl-0">
                                    <div className="font-medium text-gray-900">{product.title}</div>
                                    <div className="flex py-5">
                                        {
                                            product.tags && product.tags?.map((tag) => <div
                                                style={{ background: `rgba(${tag?.bg_color?.r}, ${tag?.bg_color?.g}, ${tag?.bg_color?.b}, ${tag?.bg_color?.a})` }}
                                                className="text-white mr-4 text-xs inline-flex items-center font-bold leading-sm uppercase px-3 py-1 rounded-full cursor-pointer"
                                            >
                                                {tag.name}
                                            </div>)
                                        }
                                    </div>
                                </td>
                                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">{product.quantity}</td>
                                <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">${Number(product?.price)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                                <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${product?.totalPrice?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th
                                scope="row"
                                colSpan={3}
                                className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                            >
                                Subtotal
                            </th>
                            <th scope="row" className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden">
                                Subtotal
                            </th>
                            <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">${order.subtotal?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</td>
                        </tr>
                        <tr>
                            <th
                                scope="row"
                                colSpan={3}
                                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                            >
                                Delivery Fee
                            </th>
                            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden">
                                Delivery Fee
                            </th>
                            <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                                {
                                    order.delivery ? '$' + Number(order.delivery).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'TBD'
                                }
                            </td>
                        </tr>
                        <tr>
                            <th
                                scope="row"
                                colSpan={3}
                                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                            >
                                Total
                            </th>
                            <th scope="row" className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden">
                                Total
                            </th>
                            <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                                ${Number(order.grandTotal)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            {
                authRedux.user.claims.role === 0 && order.status == 'submitted' ? <div className='mt-8 flex justify-center'>
                    <form onSubmit={submitOrderForm}>
                        <button type="submit" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>

                            {
                                orderRedux.isButtonLoading ? <span> Completing Order...</span> : <span className='ml-2'>Complete Order</span>
                            }
                        </button>
                    </form>
                    <form onSubmit={cancelOrderForm} className="ml-3">
                        <button type="submit" className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>


                            {
                                orderRedux.isButtonLoading ? <span> Canceling Order...</span> : <span className='ml-2'>Cancel Order</span>
                            }
                        </button>
                    </form>
                </div> : null
            }
        </div>
    )
}
