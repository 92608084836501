import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isListLoading: false,
    isSingleLoading: false,
    isButtonLoading: false,
    products: [],
}

const productSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        getProducts (state) {
            state.isListLoading = true
        },
        getProductsSaga (state, action) {
            state.products = action.payload.products
            state.isListLoading = false
        },
        getSingleProduct (state) {
            state.isSingleLoading = true
        },
        getSingleProductSaga (state, action) {
            state.products = [action.payload.product, ...state.products]
            state.isSingleLoading = false
        },
        addProduct (state) {
            state.isButtonLoading = true
        },
        addProductSaga (state, action) {
            state.products.unshift(action.payload.product)
            state.isButtonLoading = false
        },
        deleteProduct (state) {
            state.isButtonLoading = true
        },
        deleteProductSaga (state, action) {
            state.products = state.products.filter(product => product.id !== action.payload.product.id ? product : null)
            state.isButtonLoading = false
        },
        editProduct (state) {
            state.isButtonLoading = true
        },
        editProductSaga (state, action) {
            state.products = state.products.map(product => product.id !== action.payload.product.id ? product : action.payload.product )
            state.isButtonLoading = false
        }

    }
})

export const {
    getProducts,
    getProductsSaga,
    getSingleProduct,
    getSingleProductSaga,
    addProduct,
    addProductSaga,
    deleteProduct,
    deleteProductSaga,
    editProduct,
    editProductSaga
} = productSlice.actions

export default productSlice.reducer