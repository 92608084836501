
import { Fragment, useState } from 'react'
import { Dialog, RadioGroup, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { useEffect } from 'react'


export default function OrderModal({ productInfo, closeModal, isOpen }) {
    const [open, setOpen] = useState(false)
    const [mainPicture, setMainPicture] = useState(0)

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    useEffect(() => {
        if (productInfo) {
            setOpen(true)
            renderImage(productInfo?.pictures)
        } else {
            setOpen(false)
        }
    }, [productInfo])


    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const changeMainPictureView = (index) => {
        setMainPicture(productInfo.pictures[index].url)
    }

    const renderImage = (pictures) => {
        let image = {};
        for (let i = 0; i < pictures?.length; i++) {
            if (pictures[i] && Object.keys(pictures[i])?.length !== 0) {
                image = pictures[i]
                setMainPicture(image.url)
                break
            }
        }
        return image?.url
    }


    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto order-form">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative flex w-full items-center overflow-hidden bg-white px-4 pt-14 pb-8 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button
                                        type="button"
                                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 sm:top-8 sm:right-6 md:top-6 md:right-6 lg:top-8 lg:right-8"
                                        onClick={() => closeModal()}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>

                                    <div className="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8">
                                        <div className="aspect-w-2 aspect-h-3 overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                                            <img src={mainPicture || '../images/placeholder.png'} className="object-cover object-center" />
                                        </div>
                                        <div className="sm:col-span-8 lg:col-span-7">
                                            <div className='flex'>
                                                {
                                                    productInfo?.pictures?.map((picture, index) => {
                                                        if (picture?.url) {
                                                            return <img
                                                                src={picture?.url}
                                                                onClick={() => changeMainPictureView(index)}
                                                                className={classNames(picture.url === mainPicture ? 'bg-slate-300' : '', "h-20 w-20 object-contain cursor-pointer hover:bg-slate-200")} />
                                                        }
                                                    })
                                                }
                                            </div>

                                            <h2 className="text-2xl font-bold text-gray-900 sm:pr-12">{productInfo?.title}</h2>

                                            <section aria-labelledby="information-heading" className="mt-2">
                                                <h3 id="information-heading" className="sr-only">
                                                    Product information
                                                </h3>

                                                <p className="text-2xl text-gray-900">${Number(productInfo?.price).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</p>

                                                {/* Reviews */}
                                                <div className="mt-6">
                                                    <h4 className="sr-only">Reviews</h4>
                                                    <div className="flex items-center">

                                                    </div>
                                                </div>
                                            </section>

                                            <section aria-labelledby="options-heading" className="mt-2">
                                                <h3 id="options-heading" className="sr-only">
                                                    Product options
                                                </h3>

                                                {/* Sizes */}
                                                <div className="mt-5">
                                                    <div className="flex items-center justify-between">
                                                        <h4 className="text-sm font-medium text-gray-900">Description</h4>
                                                    </div>

                                                    <div className='text-justify mt-5'>
                                                        {productInfo?.description}
                                                    </div>

                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
