import { combineReducers } from 'redux'

import Product from './products/ProductSlice'
import Company from './companies/CompanySlice'
import Location from './locations/LocationSlice'
import CompanyProduct from './companyProducts/CompanyProductsSlice'
import Auth from './auth/authSlice'
import Error from './errors/ErrorSlice'
import Order from './orders/OrderSlice'
import User from './users/userSlice'

export default combineReducers({
  Company,
  Product,
  User,
  Location,
  CompanyProduct,
  Auth,
  Order,
  Error
})