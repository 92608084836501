import { all, put, take, takeEvery } from 'redux-saga/effects'
import { addDocument, getDocuments, serverTimestamp, updateDocument, getSingleDocument, deleteDocument, getDocumentsWithWhere} from '../../firebase'
import { getUsers, getUsersSaga, addUser, addUserSaga, editUser, editUserSaga, hasError, getAdminsSaga, getAdmins } from './userSlice'
import { setError } from '../errors/ErrorSlice'

import axios from 'axios'

function* processGetUsers ({ payload }) {
    try {
      const users = yield getDocumentsWithWhere('users', 'company','==', payload.companyId)

      yield put( getUsersSaga({ users }))
    } catch (er) {
        console.info('error fetching users ', er)
    }
}

function* processGetAdmins ({ payload }) {
    try {
        const admins = yield getDocumentsWithWhere('users','role','==', 0)

        yield put( getAdminsSaga({ admins }))

    } catch (er) {
        console.info('error fetching admins ', er)
    }
}

function* processAddUser ({ payload }) {
    try {
        const snapshot = yield axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/createAuthUser`, payload)

       yield put( addUserSaga({ user: snapshot.data.user }) )
    } catch (er) {
        yield put( hasError() )
        yield put( setError({ error: er.response.data.message }) )

    }
}

function* processEditUser ({ payload }) {
    try {
        const snapshot = yield axios.post(`${process.env.REACT_APP_FUNCTIONS_URL}/updateAuthUser`, payload)

        yield put( editUserSaga({ user: snapshot.data.user }))
        
    } catch (er){
        yield put( hasError() )
        yield put( setError({ error: er.response.data.message }))
    }
}


export function* watchProcesses() {
    yield takeEvery(getUsers().type, processGetUsers)
    yield takeEvery(addUser().type, processAddUser)
    yield takeEvery(editUser().type, processEditUser)
    yield takeEvery(getAdmins().type, processGetAdmins)
}

function* userSaga() {
    yield all([ watchProcesses() ])
}

export default userSaga