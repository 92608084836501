/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { LockClosedIcon } from '@heroicons/react/20/solid'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginUser } from '../redux/auth/authSlice'

import EmailField from '../components/forms/EmailField'
import PasswordField from '../components/forms/PasswordField'
import ErrorMessage from '../components/ErrorMessage'
import { clearError } from '../redux/errors/ErrorSlice'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const [submitted, setSubmitted] = useState(false)
    const [loginForm, setLoginForm] = useState()
 
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const errorRedux = useSelector(state => state.Error)
    const authRedux = useSelector(state => state.Auth)

    const submitForm = (ev) => {
        ev.preventDefault()
        setSubmitted(true)

        dispatch( clearError())

        let hasError = false

        for (const key in loginForm) {
            if (loginForm[key].isRequired && !loginForm[key].fieldValue || loginForm[key].error) {
                hasError = true
                break
            } else {
                hasError = false
            }
        }

        if (!hasError ) {
            const email = loginForm.email.fieldValue
            const password = loginForm.password.fieldValue

            dispatch(loginUser({ email: email, password: password }))
        }



    }

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto h-40 w-auto"
              src="images/logo-outline.png"
              alt="Your Company"
            />
            <h2 className="mt-14 text-center text-3xl font-bold tracking-tight text-gray-900">
              Sign in to your account
            </h2>

          </div>
          <form className="mt-8 space-y-6" onSubmit={submitForm}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div>
                <EmailField 
                    label="Email Address" 
                    fieldName="email"
                    isSubmitted={submitted}
                    isRequired
                    onChange={e => setLoginForm( prev => ({...prev, email: e}))} />
              </div>
              <div>
                <PasswordField 
                    label="Password"
                    isRequired
                    fieldName="password"
                    isSubmitted={submitted}
                    onChange={e => setLoginForm( prev => ({...prev, password: e}))} />
              </div>
            </div>


            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a onClick={() => navigate('/forgot')} className="font-medium text-indigo-600 cursor-pointer hover:text-indigo-500">
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                {
                    authRedux.isButtonLoading ? 'Signing in...' : 'Sign in'
                }
              </button>
            </div>

            { errorRedux.errorMessage ?   <ErrorMessage message={errorRedux.errorMessage} /> : null }
          

          </form>
        </div>
      </div>
    </>
  )
}
