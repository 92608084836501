import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'

import OrdersSuperAdmin from './components/OrdersSuperAdmin'
import OrdersManager from './components/OrdersManager'
import OrdersStaff from './components/OrdersStaff'

const Orders = () => {
  const navigate = useNavigate()

  const authRedux = useSelector(state => state.Auth)
  const orderRedux = useSelector(state => state.Order)
  const locationRedux = useSelector(state => state.Location)
  const userRedux = useSelector(state => state.User)
  const companyRedux = useSelector(state => state.Company)

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  console.info( ' authRedux ?? ', authRedux )

  const gotoSingleOrder = (order) => {
    if (order.status === 'pending') {
      navigate(`${order.id}/edit`)
    } else {
      navigate(order.id)
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Orders</h1>

        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            onClick={() => navigate('add')}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
          >
            Place Order
          </button>
        </div>
      </div>
      <div className="mt-10 flex flex-col">
        <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            {
              orderRedux?.orders?.length == 0 ? <div className='border-2 py-3 rounded-md'> <p> No orders</p>  </div> : <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                {
                  authRedux?.user?.claims?.role == 1 ? <OrdersManager onRowClick={(e) => gotoSingleOrder(e.order)} orders={orderRedux.orders} locations={locationRedux.locations} users={userRedux.users} /> : null
                }
                {
                  authRedux?.user?.claims?.role == 0 ? <OrdersSuperAdmin onRowClick={e => gotoSingleOrder(e.order)} orders={orderRedux.orders} companies={companyRedux.companies} /> : null
                }
                {
                  authRedux?.user?.claims?.role == 2 ? <OrdersStaff onRowClick={e => gotoSingleOrder(e.order)} orders={orderRedux.orders} users={userRedux.users} /> : null
                }
              </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

export default Orders