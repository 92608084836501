import { useState, useEffect } from "react"

export default function ImagePickerField({ fieldName, label, onChange, isSubmitted, isRequired, defaultValue }) {

    const [fileValue, setFileValue] = useState(null)
    const [errorValue, setErrorValue] = useState('')

    useEffect(() => {
        checkRequired()

        onChange({
            fieldValue: fileValue,
            fieldName: fieldName,
            error: errorValue,
            isSubmitted: isSubmitted,
            isRequired: isRequired
        })
    }, [fileValue, isSubmitted, errorValue])

    useEffect(() => {
        if (defaultValue?.url) {
            setFileValue({...defaultValue})
        }
    }, [defaultValue?.url])

    const classNames = (...classes) => {
        return classes.filter(Boolean).join(' ')
    }
    const checkRequired = () => {
        if (isSubmitted && isRequired) {
            if (!fileValue) {
                return setErrorValue(`${fieldName} is required`)
            } else {
                return setErrorValue('')
            }
        }
    }

    const onFileChange = (ev) => {
        const file = ev.target.files[0];

        const productImage = {
            path: file,
            url: URL.createObjectURL(file),
            extension: file.type.split('/').pop()
        }

        setFileValue(productImage)
        ev.target.value = null
    }

    const removeImage = () => {
        setFileValue(null)
    }

    return <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2 text-left" >
            {label}
        </label>
        <input
            className={classNames(
                errorValue ? 'border-red-500' : '',
                'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline'
            )}
            type="file"
            accept="image/*"
            onChange={onFileChange}
        />
        {
            fileValue ? <div className="border-2 border-indigo-500 w-60 p-2"> <img className="w-60 h-60 object-contain" src={fileValue.url} /> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-red-600 cursor-pointer" onClick={() => removeImage()}>
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
            </svg>
            </div> : null
        }

        {
            errorValue ? <p className="text-red-500 font-bold text-xs italic text-left"> {errorValue} </p> : null
        }

    </div>
}