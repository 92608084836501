import { all } from 'redux-saga/effects'
import productSaga from './products/ProductSaga'
import companySaga from './companies/CompanySaga'
import locationSaga from './locations/LocationSaga'
import companyProductSaga from './companyProducts/CompanyProductsSaga'
import authSaga from './auth/authSaga'
import orderSaga from './orders/OrderSaga'
import userSaga from './users/userSaga'

export default function* rootSaga() {
  yield all([ 
    companySaga(),
    productSaga(),
    locationSaga(),
    companyProductSaga(),
    authSaga(),
    orderSaga(),
    userSaga()
  ])
}