import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3BottomLeftIcon,
  UserCircleIcon,
  XMarkIcon,
  HomeIcon,
  DocumentChartBarIcon,
  BuildingOffice2Icon,
  ArrowLeftOnRectangleIcon,
  ShoppingBagIcon,
} from '@heroicons/react/24/outline'
import { Link, useLocation, useParams } from 'react-router-dom'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import SuperAdmin from '../routes/SuperAdmin'
import Manager from '../routes/Manager'
import Staff from '../routes/Staff'

import Dashboard from '../pages/Dashboard'
import Orders from '../pages/Orders/Orders'
import Companies from '../pages/Companies'
import Products from '../pages/Products'
import CompanyProducts from '../pages/CompanyProducts'
import UsersList from '../pages/Users/UsersList'

import getRoutes from '../routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../redux/auth/authSlice'
import { useEffect } from 'react'

function SideBar({ user }) {


  const location = useLocation()
  const dispatch = useDispatch()


  const authRedux = useSelector(state => state.Auth)

  const roles = ['Super Admin', 'Manager', 'Staff']

  const userNavigation = [
    { name: 'Your Profile', path: '/profile' },
    { name: 'Sign out', path: '#', action: () => dispatch(logoutUser()) },
  ]

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
  }

  const getPathName = () => {
    if (authRedux?.user?.claims?.role === 0) {
      return `/${location.pathname.split('/')[1]}`
    } else {
      return `/${location.pathname.split('/')[3]}`
    }
  }

  const getItemPath = (path) => {
    if (authRedux?.user?.claims?.role === 0) {
      return path
    } else {
      return '/' + path?.split('/')[3]
    }
  }

  const logout = () => {
    dispatch(logoutUser())
  }

  const routes = getRoutes(
    Number( authRedux.user.claims.role ), authRedux.user.claims.company, authRedux.user.claims.location
  )

  const navigation = routes[user.claims.role]

  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800 pt-5 pb-4">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex py-3 h-40 flex-shrink-0 justify-center items-center bg-black px-4">
                    <img
                      className='h-44 w-auto'
                      src='images/logo.png' />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto">
                    <nav className="space-y-1 px-2">
                      {navigation?.map((item) => (

                        item.isFunction ? <a
                          key={item.name}
                          className={classNames(
                            getItemPath(item.path) == getPathName() && !item.isFunction
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                          )}
                          onClick={() => logout()}
                          to={item.path}>
                          <item.icon className={classNames(
                            item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                            aria-hidden="true" />
                          {item.name}
                        </a> : <Link
                          key={item.name}
                          to={item.path}
                          className={classNames(
                            getItemPath(item.path) == getPathName()
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>


                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex py-3 h-40 flex-shrink-0 justify-center items-center bg-black px-4">
              <img
                className='h-44 w-auto'
                src='images/logo.png' />
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto">
              <nav className="flex-1 space-y-1 px-2 py-4">
                {navigation?.map((item) => (

                  item.isFunction ? <a
                    key={item.name}
                    className={classNames(
                      getItemPath(item.path) == getPathName() && !item.isFunction
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer'
                    )}
                    onClick={() => logout()}
                    to={item.path}>
                    <item.icon className={classNames(
                      item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                      'mr-4 flex-shrink-0 h-6 w-6'
                    )}
                      aria-hidden="true" />
                    {item.name}
                  </a> : <Link
                    key={item.name}
                    to={item.path}
                    className={classNames(
                      getItemPath(item.path) == getPathName()
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-4 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>


                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:pl-64">
          <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow">
            <button
              type="button"
              className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex flex-1 justify-between px-4">
              <div className="flex flex-1">

              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <div className='flex flex-col'>
                  <p> {user.displayName} </p>
                  <span className='text-indigo-600 text-xs text-right'> {roles[user.claims.role]} </span>
                </div>


                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open user menu</span>
                      <UserCircleIcon className='h-10 w-10 text-indigo-600' />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.path}
                              { ... item.action && {
                                onClick: () => item.action()
                              }}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">

            <div className="py-6">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                { console.info( 'auth redux ?? ', authRedux.user )}
                {
                  authRedux.user.claims.role == 0 ? <SuperAdmin /> :
                    authRedux.user.claims.role == 1 ? <Manager user={user} companyId={user.claims.company} /> :
                    authRedux.user.claims.role == 2 ? <Staff user={user} companyId={user.claims.company} locationId={user.claims.location} /> : null
                }
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}


export default SideBar