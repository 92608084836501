import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isButtonLoading: false,
    user: {},
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginUser (state) {
            state.isButtonLoading = true
        },
        setUser (state) {
            
        },
        setUserSaga (state, action) {
            state.user = action.payload.user
        },
        hasError (state) {
            state.isButtonLoading = false
        },
        loginUserSaga (state, action) {
            state.user = action.payload.user
            state.isButtonLoading = false
        },
        forgotPass (state) {
            state.isButtonLoading = true
        },
        forgotPassSaga (state) {
            state.isButtonLoading = false
        },
        logoutUser (state) {
            state.isButtonLoading = true
        },
        logoutUserSaga (state, action) {
            state.user = null
            state.isButtonLoading = false
        },
        listenUser (state) {
            state.isButtonLoading = true
        },
        listenUserSaga (state, action) {
            state.products.unshift(action.payload.product)
            state.isButtonLoading = false
        },

    }
})

export const {
    loginUser,
    loginUserSaga,
    logoutUser,
    forgotPass,
    forgotPassSaga,
    logoutUserSaga,
    listenUser,
    setUser,
    setUserSaga,
    hasError,
    listenUserSaga,
} = authSlice.actions

export default authSlice.reducer